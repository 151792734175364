import { FunctionComponent } from 'react';
import { createStyles, Text, TextProps } from '@mantine/core';

const useStyles = createStyles(theme => ({
  text: {
    fontFamily: 'Nunito Sans, sans-serif',
  },
}));

export const NumericText: FunctionComponent<TextProps> = props => {
  const { classes } = useStyles();
  return (
    <Text className={classes.text} {...props}>
      {props.children}
    </Text>
  );
};
