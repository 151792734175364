import { Grid } from '@mantine/core';
import { useSelector } from 'react-redux';

import { RootState } from 'src/redux/reducers';
import { ModerationPreviewCard } from './ModerationPreviewCard';

export const ModerationPreview = () => {
  const preview = useSelector(
    (state: RootState) => state.dashboard.moderationPreview
  );

  // Hide if there is no data
  if (preview!.toActionTotal === 0 && preview!.moderatedTotal === 0)
    return <div></div>;

  return (
    <section className="page-section">
      <Grid>
        {preview!.toActionTotal > 0 && (
          <Grid.Col span={6}>
            <ModerationPreviewCard
              title="TO ACTION"
              numModerationQueueEntries={preview!.toActionTotal}
              abuseTags={preview!.toActionAbuseTags}
              isUnresolved={true}
              button={{
                title: 'Take Action Now',
                href: '/action-log',
                variant: 'filled',
              }}
            />
          </Grid.Col>
        )}
        {preview!.moderatedTotal > 0 && (
          <Grid.Col span={6}>
            <ModerationPreviewCard
              title="MODERATED"
              numModerationQueueEntries={preview!.moderatedTotal}
              abuseTags={preview!.moderatedAbuseTags}
              isUnresolved={false}
              button={{
                title: 'See Action Log',
                href: '/action-log',
                variant: 'outline',
              }}
            />
          </Grid.Col>
        )}
      </Grid>
    </section>
  );
};
