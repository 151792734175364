import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getPresetDateRange } from 'src/utils/date';
import {
  ModerationPreview,
  DateRange,
  AllAccountStats,
  DatePreset,
  TrackedUsersStatsResponse,
  TrackedPlatformsStatsResponse,
} from 'src/types';

export interface DashboardState {
  dateRangeFilter: DateRange;
  aretoScoreFilter: string;
  trackedAccountsFilter: number[];
  moderationPreview: ModerationPreview | undefined;
  accountStats: AllAccountStats | undefined;
  trackedAccountStats: TrackedUsersStatsResponse | undefined;
  trackedPlatformStats: TrackedPlatformsStatsResponse | undefined;
}

const initialState = {
  dateRangeFilter: getPresetDateRange('last24Hours' as DatePreset),
  aretoScoreFilter: 'ALL',
  trackedAccountsFilter: [],
  moderationPreview: undefined,
  accountStats: undefined,
  trackedAccountStats: undefined,
  trackedPlatformStats: undefined,
} as DashboardState;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDateRangeFilter(state, action: PayloadAction<DateRange>) {
      state.dateRangeFilter = action.payload;
    },
    setAretoScoreFilter(state, action: PayloadAction<string>) {
      state.aretoScoreFilter = action.payload;
    },
    setsFilter(state, action: PayloadAction<string[]>) {
      state.trackedAccountsFilter = action.payload.map(v => Number(v));
    },
    setModerationPreview(state, action: PayloadAction<ModerationPreview>) {
      state.moderationPreview = action.payload;
    },
    setAccountStats(state, action: PayloadAction<AllAccountStats>) {
      state.accountStats = action.payload;
    },
    setStats(state, action: PayloadAction<TrackedUsersStatsResponse>) {
      state.trackedAccountStats = action.payload;
    },
    setPlatformStats(
      state,
      action: PayloadAction<TrackedPlatformsStatsResponse>
    ) {
      state.trackedPlatformStats = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState;
    });
  },
});

export const {
  setDateRangeFilter,
  setAretoScoreFilter,
  setsFilter,
  setModerationPreview,
  setAccountStats,
  setStats,
  setPlatformStats,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
