export enum LanguageType {
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  SPANISH = 'es',
}

export const LanguageTypeLabels: Record<string, string> = {
  en: 'English',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  es: 'Spanish',
};
