import { Title, Text, Group } from '@mantine/core';
import { Anchor } from 'src/components';

export default function Reset() {
  return (
    <>
      <Title order={1} mb="md">
        No worries, it happens
      </Title>
      <Text mb="md">
        If you've forgotten your password, please reach out to{' '}
        <Anchor to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Anchor>
        , and we'll send you a temporary password.
      </Text>
      <Group spacing={'xs'}>
        <Text>Remember your password?</Text>
        <Anchor to="/login">Sign In.</Anchor>
      </Group>
    </>
  );
}
