import { FunctionComponent, ReactNode } from 'react';
import {
  Container,
  createStyles,
  Group,
  Title,
  Text,
  ContainerProps,
} from '@mantine/core';
import { Navigate, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { RootState } from 'src/redux/rootReducer';

const useStyles = createStyles(theme => ({
  container: {
    paddingTop: 64,
    paddingBottom: 64,
    width: 1800,
    maxWidth: 'calc(100% - 168px)',
    minHeight: '100%',
    paddingLeft: '98px !important',
    paddingRight: '98px !important',
  },
  header: {
    paddingBottom: 16,
    marginBottom: 24,
    borderBottom: `solid 1px ${theme.colors.dark[4]}`,
    '&.no-border': {
      border: 'none',
    },
  },
  description: {
    margin: '16px 0px 24px 0px',
  },
}));

// @ts-ignore
export interface PageProps extends ContainerProps {
  title?: string;
  noBorder?: boolean;
  right?: ReactNode;
  description?: string;
}

export const Page: FunctionComponent<PageProps> = props => {
  const location = useLocation();

  const { classes } = useStyles();
  const { noBorder, title, right, description, ...containerProps } = props;
  const isLoggedIn = useSelector((state: RootState) => state.user.token);

  if (!isLoggedIn && location.pathname !== '/account-deactivated')
    return <Navigate replace={true} to="/login" />;

  return (
    <Container
      {...containerProps}
      className={classes.container + ` ${props.className}`}
    >
      {props.title && (
        <header
          className={classes.header + (props.noBorder ? ' no-border' : '')}
        >
          <Group position="apart">
            <Title order={1}>
              {props.title} {props.noBorder}
            </Title>
            {props.right && <div>{props.right}</div>}
          </Group>
          {props.description && (
            <Text className={classes.description}>{props.description}</Text>
          )}
        </header>
      )}
      {props.children}
    </Container>
  );
};
