import { Card, Grid, Stack, Space, Text, Divider } from '@mantine/core';

import { NumericText, PlatformProgress } from 'src/components';
import { PercentFormat, CountFormat } from 'src/utils/constants';
import { theme } from 'src/styling/theme';
import { PlatformTypeLabels } from 'src/types';

interface PlatformComments {
  platform: string;
  numComments: number;
}

interface CommentsCardProps {
  title: string;
  isNegative: boolean;
  numComments: number;
  comments: PlatformComments[];
}

export const CommentsCard = (props: CommentsCardProps) => {
  return (
    <Card shadow="xl" radius="lg" p="xl">
      <Grid>
        <Grid.Col span={5}>
          <Stack
            align="center"
            justify="center"
            spacing="sm"
            style={{ height: '100%' }}
          >
            <NumericText size={48} weight={700}>
              {CountFormat.format(props.numComments)}
            </NumericText>
            <Text
              size="md"
              color={theme.colors.dark[2]}
              transform="uppercase"
              weight={500}
            >
              {props.title}
            </Text>
            <Space h="sm" />
          </Stack>
        </Grid.Col>
        <Divider orientation="vertical" mt={8} mb={18} />
        <Space w="lg" />
        <Grid.Col span={6}>
          {props.comments.map(({ platform, numComments }) => (
            <PlatformProgress
              key={platform}
              platform={PlatformTypeLabels[platform]}
              progress={numComments / props.numComments || 0}
              label={
                <NumericText size="xs">
                  {PercentFormat.format(numComments / props.numComments || 0)}{' '}
                  of {CountFormat.format(props.numComments)}
                </NumericText>
              }
              my={16}
            />
          ))}
        </Grid.Col>
      </Grid>
    </Card>
  );
};
