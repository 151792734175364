import { useState, useCallback, useEffect } from 'react';

import { OrganizationResponse, Response } from 'src/types';
import { setOrganization } from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

export const useFetchOrganization = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const state = store.getState();

  const fetch = useCallback(async () => {
    try {
      const response = await client.get('/accounts/organization');
      const data = response.data as Response<OrganizationResponse>;
      store.dispatch(setOrganization(data.response.organization));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!state.user.token || state.user.organization) return;
    fetch();
  }, [state.user.token, state.user.organization, fetch]);

  return { error, loading, fetch };
};
