import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useTransition,
  SyntheticEvent,
} from 'react';
import {
  Title,
  Checkbox,
  Group,
  Button,
  Space,
  Text,
  Container,
  Grid,
} from '@mantine/core';
import { Icon } from '@iconify/react';
import { z } from 'zod';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { TextInput, PasswordInput, Anchor } from 'src/components';
import { validateFormSubmission } from 'src/utils/forms';
import login from 'src/api/auth/login';
import { LoginRequest } from 'src/types';
import { setToken } from 'src/redux/reducers/userReducer';

export const loginSchema = z.object({
  email: z.string().trim().email(),
  password: z.string().trim(),
});

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line
  const [disabled, startTransition] = useTransition();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitError, setSubmitError] = useState<string>();

  const loginRequest = async (e: SyntheticEvent) => {
    e.preventDefault();

    const data = {
      email: emailRef.current?.value,
      password: passwordRef.current?.value,
    };

    const result = await validateFormSubmission(data, loginSchema);

    if (!result.success) {
      setSubmitError(result.error.message);
      return;
    }

    const response = await login(result.data as LoginRequest);

    if (!response || response?.meta.code !== 200) {
      setSubmitError('Invalid email or password.');
      return;
    }

    dispatch(setToken(response.response.user.authentication_token));

    navigate('/');
  };

  const refreshSubmitDisabled = useCallback(() => {
    setSubmitDisabled(
      Boolean(!emailRef.current?.value || !passwordRef.current?.value)
    );
  }, [setSubmitDisabled]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSubmitDisabled();
    }, 250);
    return () => clearInterval(interval);
  }, [refreshSubmitDisabled]);

  return (
    <form onSubmit={loginRequest}>
      <Title order={1} mb={56}>
        Log in to Areto
      </Title>
      <div style={{ position: 'relative' }}>
        <img
          src="/flare/lemon.svg"
          alt="Lemon decoration"
          width="96"
          style={{
            position: 'absolute',
            right: 16,
            top: -54,
            clipPath: 'polygon(0 0, 100% 0, 100% 50%, 0 50%)',
          }}
        />
        <TextInput
          id="email"
          name="email"
          required
          label="Email"
          icon={<Icon icon="carbon:email" />}
          placeholder="youremail@awesome.com"
          onChange={refreshSubmitDisabled}
          ref={emailRef}
          disabled={disabled}
          style={{
            position: 'relative',
          }}
        />
      </div>
      <Space h="xl" />
      <PasswordInput
        id="password"
        name="password"
        required
        label="Password"
        icon={<Icon icon="carbon:locked" />}
        placeholder="Your password"
        onChange={refreshSubmitDisabled}
        ref={passwordRef}
        disabled={disabled}
      />
      <Space h="md" />
      <Group position="apart">
        <Checkbox label="Remember me" />
        <Anchor
          to="/reset"
          rel="prefetch"
          style={{ textDecoration: 'none', textAlign: 'right' }}
        >
          Forgot password?
        </Anchor>
      </Group>
      <Space h={48} />
      <Button
        variant="filled"
        color="primary"
        size="lg"
        radius="xl"
        fullWidth
        type="submit"
        loading={disabled}
        disabled={submitDisabled}
      >
        Continue
      </Button>
      <div style={{ textAlign: 'center' }}>
        <Space h={8} />
        <Text style={{ minHeight: '32px', color: '#FF6738' }}>
          {submitError ?? ' '}
        </Text>
        <Container mb="xl" p="sm">
          <Grid grow={false} justify="center">
            <Text size="sm">Don't have an Areto account?&nbsp;</Text>
            <Anchor to="/register" size="sm">
              Register now.
            </Anchor>
          </Grid>
        </Container>
      </div>
    </form>
  );
}
