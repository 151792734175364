import { useCallback } from 'react';

import { Group, Button, Grid, createStyles } from '@mantine/core';
import { modals } from '@mantine/modals';
import { PlatformIcon, AbuseTags } from 'src/components';
import { ModerationResolveTypeLabels, ActionLogEntry } from 'src/types';
import { parseToLocalDateTime } from 'src/utils/date';
import { useSendTranslateComment } from 'src/api';

const useStyles = createStyles(theme => ({
  commentPlatformIcon: {
    padding: '6px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: theme.colors.dark[0],
  },
}));

interface DetailRowProps {
  label: string;
  value: string | React.ReactNode;
  titleSpan?: number;
  contentSpan?: number;
  padding?: string;
}

const DetailRow: React.FC<DetailRowProps> = ({
  label,
  value,
  titleSpan = 4,
  contentSpan = 8,
  padding = '0 0 8px 0',
}) => {
  return (
    <Grid>
      <Grid.Col span={titleSpan} style={{ padding: padding }}>
        {label}
      </Grid.Col>
      <Grid.Col span={contentSpan} style={{ padding: padding }}>
        {value}
      </Grid.Col>
    </Grid>
  );
};

export const CommentModal = ({ entry }: { entry: ActionLogEntry }) => {
  const { classes } = useStyles();

  const {
    loading: loadingTranslate,
    data: translatedText,
    send: sendTranslate,
    reset: resetTranslate,
  } = useSendTranslateComment(entry?.comment.text || '');

  const toggleTranslate = useCallback(() => {
    if (translatedText) {
      resetTranslate();
    } else {
      sendTranslate();
    }
  }, [resetTranslate, sendTranslate, translatedText]);

  if (!entry) return <div></div>;

  return (
    <>
      <Grid align="center">
        <Grid.Col
          span={1}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PlatformIcon
            fontSize={32}
            platform={entry.trackedUser.platform}
            className={classes.commentPlatformIcon}
          />
        </Grid.Col>
        <Grid.Col span={10}>
          <DetailRow
            label="TO:"
            value={entry.trackedUser.platformUsername}
            titleSpan={3}
            contentSpan={9}
            padding="0px"
          />
          <DetailRow
            label="FROM:"
            value={entry.comment.author}
            titleSpan={3}
            contentSpan={9}
          />
        </Grid.Col>
      </Grid>
      <DetailRow
        label="COMMENT:"
        value={translatedText ? translatedText : entry.comment.text}
      />
      <DetailRow
        label="POSTED:"
        value={parseToLocalDateTime(entry.comment.timestamp)}
      />
      <DetailRow
        label="STATUS:"
        value={
          ModerationResolveTypeLabels[entry.resolution.status] || 'Unresolved'
        }
      />
      {entry.resolution.timestamp && (
        <DetailRow
          label="RESOLVED:"
          value={parseToLocalDateTime(entry.resolution.timestamp)}
        />
      )}
      <DetailRow
        label="ABUSE TYPES:"
        value={
          <AbuseTags tags={entry.abuseTags} expanded={true} isDark={true} />
        }
      />
      <Group position="right" mt={16}>
        <Button variant="subtle" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button
          variant="filled"
          loading={loadingTranslate}
          onClick={toggleTranslate}
        >
          {translatedText ? 'Show Original' : 'Translate'}
        </Button>
        <Button
          variant="filled"
          onClick={() =>
            navigator.clipboard.writeText(
              translatedText ? translatedText : entry.comment.text
            )
          }
        >
          Copy
        </Button>
      </Group>
    </>
  );
};
