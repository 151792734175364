import { useState, useCallback } from 'react';

import { notifications } from '@mantine/notifications';

import { TranslateResponse } from 'src/types';

import client from '../client';

export const useSendTranslateComment = (text: string) => {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const send = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.post('/platforms/translate', {
        text: text,
      });
      const data = response.data.response as TranslateResponse;
      setData(data.translatedText);
    } catch (error: any) {
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [text]);

  const reset = useCallback(() => {
    setData(null);
  }, []);

  return { loading, data, send, reset };
};
