import { modals } from '@mantine/modals';
import { Button, Text, Stack, Group } from '@mantine/core';

import { useFetchOrganization, useSendDeactivateAccount } from 'src/api';
import { ErrorMessage } from 'src/components';
import { useCallback } from 'react';

export const DeactivateUserAccountModal = ({
  accountId,
  name,
}: {
  accountId: number;
  name: string;
}) => {
  const { send, loading, error } = useSendDeactivateAccount();
  const { fetch } = useFetchOrganization();

  const handleClick = useCallback(async () => {
    const success = await send(accountId);

    if (success) {
      modals.closeAll();
      fetch();
    }
  }, [send, accountId, fetch]);

  return (
    <Stack>
      <Text>Are you sure you want to deactivate {name}'s account?</Text>
      <Group position="right">
        <Button variant="subtle" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button variant="error" loading={loading} onClick={() => handleClick()}>
          Yes, deactivate
        </Button>
      </Group>
      <ErrorMessage error={error} />
    </Stack>
  );
};
