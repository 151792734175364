import { useState, useCallback, useEffect } from 'react';

import { AuthLinkResponse, Response } from 'src/types';
import { PlatformType } from 'src/types';

import client from '../client';

export const useFetchAuthLink = (platform: PlatformType) => {
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const _determineEndpoint = (platform: PlatformType): string => {
    if (platform === PlatformType.TIKTOK) return '/platforms/tiktok/auth-link';
    else if (platform === PlatformType.YOUTUBE)
      return '/platforms/youtube/auth-link';

    return '/platforms/meta/auth-link';
  };

  const _createParams = (platform: PlatformType): { is_initial?: boolean } => {
    if (platform === PlatformType.YOUTUBE) {
      return { is_initial: false };
    }

    return {};
  };

  const fetch = useCallback(async () => {
    try {
      const response = await client.get(_determineEndpoint(platform), {
        params: _createParams(platform),
      });
      const data = response.data as Response<AuthLinkResponse>;
      setData(data.response.authLink);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [platform]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { error, loading, data };
};
