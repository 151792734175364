import {
  Text,
  Stack,
  Button,
  List,
  useMantineTheme,
  Container,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Anchor } from 'src/components';

import { parseToLocalDate } from 'src/utils/date';

interface StepProps {
  isClient: boolean;
}

export const StepFiveSuccess = (props: StepProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/login');
  };

  const computeTrialEndData = () => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    return parseToLocalDate(endDate);
  };

  return (
    <Stack spacing="sm">
      {props.isClient ? (
        <Text>Congrats! You have now registered for Areto!</Text>
      ) : (
        <Text>
          Congrats! You have now registered for your
          <span style={{ color: theme.colors.secondary[0] }}>
            {' '}
            14 day free trial{' '}
          </span>
          with Areto. Your free trial will end on
          <span style={{ color: theme.colors.secondary[0] }}>
            {' '}
            {computeTrialEndData()}
          </span>
          .
        </Text>
      )}
      <Text>
        <span style={{ color: theme.colors.secondary[0] }}>Note:</span> Only
        Instagram Professional accounts (Business or Creator) with a linked
        Facebook Page can connect to Areto. Requirements:
      </Text>
      <Text weight="bold">Facebook</Text>
      <List>
        <List.Item>
          Meta Business Suite account{' '}
          <Anchor
            to="https://www.facebook.com/business/help/1710077379203657?id=180505742745347"
            target="_blank"
            rel="noreferrer noopener"
          >
            (how to: create a business portfolio)
          </Anchor>
        </List.Item>
        <List.Item>
          Facebook Page is linked to your Meta Business Suite account{' '}
          <Anchor
            to="https://www.facebook.com/business/help/720478807965744?id=420299598837059"
            target="_blank"
            rel="noreferrer noopener"
          >
            (how to: add a page)
          </Anchor>
        </List.Item>
        <List.Item>Admin access to Facebook Page</List.Item>
      </List>
      <Text weight="bold">Instagram</Text>
      <List>
        <List.Item>
          Instagram Business account{' '}
          <Anchor
            to="https://help.instagram.com/502981923235522"
            target="_blank"
            rel="noreferrer noopener"
          >
            (how to: convert accounts)
          </Anchor>
        </List.Item>
        <List.Item>
          Instagram Business account is linked to your Facebook Page{' '}
          <Anchor
            to="https://www.facebook.com/business/help/connect-instagram-to-page"
            target="_blank"
            rel="noreferrer noopener"
          >
            (how to: link accounts)
          </Anchor>
        </List.Item>
      </List>
      <Container
        style={{ backgroundColor: theme.colors.secondary[0], borderRadius: 10 }}
        p="sm"
      >
        <Text color="black">
          💡 Reminder: To finish setting up Areto, you need to{' '}
          <span style={{ fontWeight: 'bold' }}>
            sign in and connect your social media accounts
          </span>
          .
        </Text>
      </Container>

      <Button mt="md" onClick={navigateToLogin}>
        Sign In To Get Started
      </Button>
    </Stack>
  );
};
