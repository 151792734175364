import { createStyles, Stack, Text, Image, Space } from '@mantine/core';

const useStyles = createStyles(theme => ({
  root: {
    width: 500,
    height: '100%',
    margin: 'auto',
  },
}));

export interface EmptyProps {
  title: React.ReactNode;
  message: React.ReactNode;
}

export const Empty = (props: EmptyProps) => {
  const { classes } = useStyles();

  return (
    <Stack className={classes.root} align="center" justify="center" spacing={0}>
      <Image
        src="/flare/lemon-slice.svg"
        alt="Empty cup of lemonade"
        width={80}
      />
      <Space h={16} />
      <div>
        <Text size={24} align="center" mb={16}>
          {props.title}
        </Text>
        <Text size={18} weight={300} align="center">
          {props.message}
        </Text>
      </div>
    </Stack>
  );
};
