import { useEffect, useState } from 'react';
import { Group, Space, Title } from '@mantine/core';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';

import { Page, Loading, ActionLogTabs, ActionLogFilters } from 'src/components';
import { RootState } from 'src/redux/reducers';
import { useFetchActionLog, useFetchActionLogTotals } from 'src/api';

export default function ActionLogPage() {
  const isLoggedIn = useSelector((state: RootState) => state.user.token);
  const actionLog = useSelector((state: RootState) => state.actionLog);
  const [errorShown, setErrorShown] = useState(false);

  const { loading, error } = useFetchActionLog();
  const { loading: loadingTotals, error: errorTotals } =
    useFetchActionLogTotals();

  useEffect(() => {
    if ((error || errorTotals) && isLoggedIn && !errorShown) {
      setErrorShown(true);
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      });
    }
  }, [error, errorTotals, errorShown, isLoggedIn]);

  if (!actionLog.loaded && isLoggedIn) return <Loading />;

  return (
    <Page noBorder>
      <Group position="apart">
        <Title order={1}>Action Log</Title>
        <ActionLogFilters loadingData={loading || loadingTotals} />
      </Group>
      <Space h={32} />
      <ActionLogTabs />
    </Page>
  );
}
