import { useSelector } from 'react-redux';

import { PlatformsTable } from './PlatformsTable';
import { RootState } from 'src/redux/rootReducer';

export const Platforms = () => {
  const platforms = useSelector(
    (state: RootState) => state.dashboard.trackedPlatformStats
  );

  return <PlatformsTable platforms={platforms!} />;
};
