import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type { AnyAction } from '@reduxjs/toolkit';

import userReducer from './reducers/userReducer';
import dashboardReducer from './reducers/dashboardReducer';
import actionLogReducer from './reducers/actionLogReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // data persisted on page reload
};

const appReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  actionLog: actionLogReducer,
});

export default persistReducer(persistConfig, appReducer);
export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (
  state: RootState | undefined,
  action: AnyAction
) => {
  if (action.type === 'user/logout') {
    storage.removeItem('persist:root');
  }

  return appReducer(state, action);
};
