import { Card, Title, Text, List, Space } from '@mantine/core';

import { Anchor } from 'src/components';

export const HowToConnect = () => {
  return (
    <section className="page-section">
      <header>
        <Title order={2}>Social Media Account Requirements</Title>
      </header>
      <Card>
        <Text weight="bold">Facebook</Text>
        <List>
          <List.Item>
            Meta Business Suite account{' '}
            <Anchor
              to="https://www.facebook.com/business/help/1710077379203657?id=180505742745347"
              target="_blank"
              rel="noreferrer noopener"
            >
              (how to: create a business portfolio)
            </Anchor>
          </List.Item>
          <List.Item>
            Facebook Page is linked to your Meta Business Suite account{' '}
            <Anchor
              to="https://www.facebook.com/business/help/720478807965744?id=420299598837059"
              target="_blank"
              rel="noreferrer noopener"
            >
              (how to: add a page)
            </Anchor>
          </List.Item>
          <List.Item>Admin access to Facebook Page</List.Item>
        </List>

        <Space h="sm" />
        <Text weight="bold">Instagram</Text>
        <List>
          <List.Item>
            Instagram Professional account (Business or Creator){' '}
            <Anchor
              to="https://help.instagram.com/502981923235522"
              target="_blank"
              rel="noreferrer noopener"
            >
              (how to: convert accounts)
            </Anchor>
          </List.Item>
          <List.Item>
            Instagram Professional account is linked to your Facebook Page{' '}
            <Anchor
              to="https://www.facebook.com/business/help/connect-instagram-to-page"
              target="_blank"
              rel="noreferrer noopener"
            >
              (how to: link accounts)
            </Anchor>
          </List.Item>
        </List>
      </Card>
    </section>
  );
};
