import { useState } from 'react';
import { useLocation } from 'react-router';
import { Stack, Stepper } from '@mantine/core';

import {
  BRAND_MODERATION_THRESHOLDS,
  INDIVIDUAL_MODERATION_THRESHOLDS,
} from 'src/utils/constants';
import { RegisterOrganizationRequestData } from 'src/types';
import { FormProps } from 'src/components';

import {
  StepTitle,
  StepOneUser,
  StepTwoSettings,
  StepThreeAdmin,
  StepFourConfirm,
  StepFiveSuccess,
  StepButtons,
  StepFooter,
} from './RegisterSteps';

export interface RegisterProps extends FormProps {
  values: RegisterOrganizationRequestData;
}

const INITIAL_STATE: RegisterOrganizationRequestData = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  accountType: 'COMPANY',
  organizationName: '',
  moderationType: 'AUTOMODERATE',
  offensiveLanguageAutoModerateThreshold:
    BRAND_MODERATION_THRESHOLDS.offensiveLanguageAutoModerateThreshold,
  offensiveLanguageModerationQueueThreshold:
    BRAND_MODERATION_THRESHOLDS.offensiveLanguageModerationQueueThreshold,
  personalAttackAutoModerateThreshold:
    BRAND_MODERATION_THRESHOLDS.personalAttackAutoModerateThreshold,
  personalAttackModerationQueueThreshold:
    BRAND_MODERATION_THRESHOLDS.personalAttackModerationQueueThreshold,
  violentLanguageAutoModerateThreshold:
    BRAND_MODERATION_THRESHOLDS.violentLanguageAutoModerateThreshold,
  violentLanguageModerationQueueThreshold:
    BRAND_MODERATION_THRESHOLDS.violentLanguageModerationQueueThreshold,
  threatAutoModerateThreshold:
    BRAND_MODERATION_THRESHOLDS.threatAutoModerateThreshold,
  threatModerationQueueThreshold:
    BRAND_MODERATION_THRESHOLDS.threatModerationQueueThreshold,
  confirm: false,
};

export default function Register() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isAdmin = params.get('user') === 'admin44132';
  const isClient = params.get('type') === 'client'; // if user signing up does not require free trial

  const [activeStep, setActiveStep] = useState(0);
  const [validateData, setValidateData] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);
  const [values, setValues] = useState(INITIAL_STATE);

  const updateModerationThresholds = (accountType: string) => {
    if (accountType === 'COMPANY') {
      setValues(prevState => ({
        ...prevState,
        ...BRAND_MODERATION_THRESHOLDS,
      }));
    } else {
      setValues(prevState => ({
        ...prevState,
        ...INDIVIDUAL_MODERATION_THRESHOLDS,
        organizationName: '',
      }));
    }
  };

  const handleSetValue = (key: string, value: string | number | boolean) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));

    if (key === 'accountType') {
      updateModerationThresholds(value as string);
    }
  };

  return (
    <div>
      <StepTitle
        activeStep={activeStep}
        isAdmin={isAdmin}
        isClient={isClient}
      />
      <Stack h={500} justify="space-between">
        <Stepper
          active={activeStep}
          breakpoint="sm"
          allowNextStepsSelect={false}
        >
          <Stepper.Step label="Account">
            <StepOneUser
              values={values}
              handleSetValue={handleSetValue}
              validate={validateData && activeStep === 0}
              setValidate={setValidateData}
              setDataValidated={setDataValidated}
            />
          </Stepper.Step>
          <Stepper.Step label="Settings">
            <StepTwoSettings
              values={values}
              handleSetValue={handleSetValue}
              validate={validateData && activeStep === 1}
              setValidate={setValidateData}
              setDataValidated={setDataValidated}
            />
          </Stepper.Step>
          {isAdmin && (
            <Stepper.Step label="Admin">
              <StepThreeAdmin
                values={values}
                handleSetValue={handleSetValue}
                validate={validateData && activeStep === 2 && isAdmin}
                setValidate={setValidateData}
                setDataValidated={setDataValidated}
              />
            </Stepper.Step>
          )}
          <Stepper.Step label="Confirm">
            <StepFourConfirm
              values={values}
              handleSetValue={handleSetValue}
              validate={
                (validateData && activeStep === 3 && isAdmin) ||
                (activeStep === 2 && !isAdmin)
              }
              setValidate={setValidateData}
              setDataValidated={setDataValidated}
            />
          </Stepper.Step>
          <Stepper.Completed>
            <StepFiveSuccess isClient={isClient} />
          </Stepper.Completed>
        </Stepper>
        <Stack>
          <StepButtons
            isAdmin={isAdmin}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setValidateData={setValidateData}
            dataValidated={dataValidated}
            setDataValidated={setDataValidated}
            values={values}
          />
          <StepFooter activeStep={activeStep} />
        </Stack>
      </Stack>
    </div>
  );
}
