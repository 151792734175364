import { FunctionComponent } from 'react';
import { Icon, IconProps } from '@iconify/react';

import { SOCIAL_PLATFORM_ICON } from 'src/utils/constants';

export interface PlatformIconProps extends Omit<IconProps, 'icon'> {
  platform: string;
}

export const PlatformIcon: FunctionComponent<PlatformIconProps> = props => {
  return (
    <Icon
      {...props}
      fontSize={props.fontSize ? props.fontSize : 16}
      icon={SOCIAL_PLATFORM_ICON[props.platform.toLowerCase()]}
    />
  );
};
