import { useEffect, useState } from 'react';

import { Stack, TextInput, Select } from '@mantine/core';
import { Icon } from '@iconify/react';
import { z } from 'zod';

import { RegisterProps } from '../Register';

const settingsSchema = z
  .object({
    accountType: z.string().trim().nonempty('This field is required. '),
    organizationName: z.string().trim(),
    moderationType: z.string().trim().nonempty('This field is required. '),
  })
  .refine(
    data =>
      data.accountType !== 'COMPANY' ||
      (data.accountType === 'COMPANY' &&
        data.organizationName &&
        data.organizationName.trim() !== ''),
    {
      message: 'This field is required. ',
      path: ['organizationName'],
    }
  );

const BRAND_MODERATION_OPTIONS = [
  { value: 'AUTOMODERATE', label: 'Auto-moderate spam & abusive comments' },
  {
    value: 'SPAM',
    label: 'Auto-moderate spam only',
  },
  {
    value: 'ABUSE',
    label: 'Auto-moderate abusive comments only',
  },
  {
    value: 'TRACK',
    label: 'Track my accounts only (no automoderation)',
  },
];

const INDIVIDUAL_MODERATION_OPTIONS = [
  {
    value: 'ABUSE',
    label: 'Auto-moderate abusive comments',
  },
  {
    value: 'TRACK',
    label: 'Track my accounts only (no automoderation)',
  },
];

export const StepTwoSettings = (props: RegisterProps) => {
  const { validate, setValidate, values, handleSetValue, setDataValidated } =
    props;

  const [moderationTypes, setModerationTypes] = useState(
    BRAND_MODERATION_OPTIONS
  );
  const [errors, setErrors] = useState<z.ZodError | null>(null);

  const setModerationSettings = (value: string) => {
    if (value === 'COMPANY') {
      setModerationTypes(BRAND_MODERATION_OPTIONS);
      handleSetValue('moderationType', 'AUTOMODERATE');
    } else {
      setModerationTypes(INDIVIDUAL_MODERATION_OPTIONS);
      handleSetValue('moderationType', 'ABUSE');
    }
  };

  useEffect(() => {
    if (validate) {
      const result = settingsSchema.safeParse(values);

      if (result.success) {
        setDataValidated(true);
      } else {
        setErrors(result.error);
      }

      setValidate(false);
    }
  }, [setErrors, validate, values, setValidate, setDataValidated]);

  return (
    <Stack spacing="sm">
      <Select
        name="accountType"
        label="I am using Areto for"
        required
        data={[
          { value: 'COMPANY', label: 'Brand account(s)' },
          {
            value: 'INDIVIDUAL',
            label: 'Individual influencer/athlete/public figure account(s)',
          },
        ]}
        value={values.accountType}
        onChange={e => {
          setModerationSettings(e!);
          handleSetValue('accountType', e!);
        }}
        mb="sm"
        mt="sm"
        error={errors?.formErrors.fieldErrors.accountType}
      />
      {values.accountType === 'COMPANY' && (
        <TextInput
          name="organizationName"
          required
          label="Organization name"
          placeholder="Name of brand or organization"
          mb="sm"
          icon={<Icon icon="carbon:building" />}
          value={values.organizationName}
          onChange={e => handleSetValue('organizationName', e.target.value)}
          error={errors?.formErrors.fieldErrors.organizationName}
        />
      )}

      <Select
        name="moderationType"
        label="I want Areto to"
        required
        data={moderationTypes}
        value={values.moderationType}
        onChange={e => handleSetValue('moderationType', e!)}
        mb="sm"
        error={errors?.formErrors.fieldErrors.moderationType}
      />
    </Stack>
  );
};
