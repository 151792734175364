import { FunctionComponent, ReactNode } from 'react';
import { Box, BoxProps, Group, Progress } from '@mantine/core';

import { PlatformLabel } from './PlatformLabel';

export interface PlatformProgressProps extends BoxProps {
  platform: string;
  progress: number;
  label?: ReactNode;
}

export const PlatformProgress: FunctionComponent<
  PlatformProgressProps
> = props => {
  const progress = props.progress * 100;
  return (
    <Box {...props}>
      <Group position="apart">
        <Group align="center" spacing={4}>
          <PlatformLabel platform={props.platform} />
        </Group>
        {props.label}
      </Group>
      <Progress value={progress} />
    </Box>
  );
};
