import { Card, Text, Title, createStyles, Button } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { AbuseTags } from 'src/components';
import { setUnresolvedTab } from 'src/redux/reducers';

const useStyles = createStyles(theme => ({
  moderationPreviewState: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    background: '#353147',
    border: '1px solid #463E61',
    borderRadius: '0px 0px 24px 0px',
    left: '0px',
    top: '0px',
    width: 'fit-content',
    height: '40px',
    paddingLeft: '24px',
    paddingRight: '24px',
    letterSpacing: '0.02em',
    color: '#D9D5E8',
    fontWeight: 500,
  },
  moderationPreviewContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 32,
  },
  moderationPreviewTags: {
    paddingLeft: 24,
  },
  moderationPreviewButton: {
    textAlign: 'center',
    width: '100%',
    height: '48px',
    marginTop: '24px',
    padding: '14px 24px',
    fontSize: '16px',
  },
}));

interface ModerationPreviewCardProps {
  title: string;
  numModerationQueueEntries: number;
  abuseTags: string[];
  isUnresolved: boolean;
  button: {
    title: string;
    href: string;
    variant: string;
  };
}

export const ModerationPreviewCard = (props: ModerationPreviewCardProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Card shadow="xl" radius="lg" p="xl">
      <Card.Section>
        <Text className={classes.moderationPreviewState}>{props.title}</Text>
      </Card.Section>
      <div className={classes.moderationPreviewContent}>
        <Title>{props.numModerationQueueEntries}</Title>
        <div className={classes.moderationPreviewTags}>
          <AbuseTags tags={props.abuseTags} numToShow={3} />
        </div>
      </div>
      <Button
        className={classes.moderationPreviewButton}
        component={Link}
        to={props.button.href}
        variant={props.button.variant}
        onClick={() => dispatch(setUnresolvedTab(props.isUnresolved))}
      >
        {props.button.title}
      </Button>
    </Card>
  );
};
