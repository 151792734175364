import { useSelector } from 'react-redux';

import { NoTrackedUsers } from './NoTrackedUsers';
import { AccountsTable } from './AccountsTable';
import { RootState } from 'src/redux/rootReducer';

export const Accounts = () => {
  const accounts = useSelector(
    (state: RootState) => state.dashboard.trackedAccountStats
  );

  return (
    <>
      {accounts && !accounts.trackedUsers.current?.length ? (
        <NoTrackedUsers />
      ) : (
        <AccountsTable accounts={accounts!} />
      )}
    </>
  );
};
