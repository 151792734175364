import { notifications } from '@mantine/notifications';

import { setAccount } from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

export const useSendUpdateAccount = () => {
  const state = store.getState();
  const profile = state.user.profile;
  const previousValue = profile?.translationLanguage || 'en';

  const updateAccountState = (translationLanguage: string) => {
    if (profile) {
      store.dispatch(
        setAccount({
          ...profile,
          translationLanguage,
        })
      );
    }
  };

  const handleSuccess = () => {
    notifications.show({
      title: 'Translation Language Updated',
      message: 'Your settings have been updated successfully.',
      color: 'teal',
    });
  };

  const handleError = () => {
    notifications.show({
      title: 'Uh-oh! Something Went Wrong',
      message:
        'We were unable to complete this action. Please try again later.',
    });

    updateAccountState(previousValue);
  };

  const send = async (newValue: string) => {
    updateAccountState(newValue);

    try {
      const response = await client.put('/accounts/account', {
        translation_language: newValue,
      });

      if (response.status === 204) {
        handleSuccess();
      }
    } catch (error: any) {
      handleError(); // error.message
    }
  };

  return { send };
};
