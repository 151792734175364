import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';

import { Empty } from 'src/components';

export const ActionLogTableEmpty = () => {
  const isUnresolved = useSelector(
    (state: RootState) => state.actionLog.unresolvedTab
  );

  const message = isUnresolved
    ? 'There are no comments to moderate for the selected filters. As soon as we detect abuse, it’ll show up here.'
    : 'There are no comments that have been moderated for the selected filters.';

  return <Empty title={'Nothing here... yet'} message={message} />;
};
