import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { modals } from '@mantine/modals';
import { Button, Text, Stack, Group } from '@mantine/core';

import { logout } from 'src/redux/reducers';
import { useSendDeactivateAccount } from 'src/api';
import { OrganizationType } from 'src/types';
import { ErrorMessage } from 'src/components';

export const DeactivateMyAccountModal = ({
  accountId,
  organizationType,
  navigateDeactivatedPage,
}: {
  accountId: number;
  organizationType: OrganizationType;
  navigateDeactivatedPage: () => void;
}) => {
  const dispatch = useDispatch();

  const { send, loading, error } = useSendDeactivateAccount();

  const handleClick = useCallback(async () => {
    const success = await send(accountId);

    if (success) {
      modals.closeAll();
      dispatch(logout());
      navigateDeactivatedPage();
    }
  }, [send, accountId, dispatch, navigateDeactivatedPage]);

  return (
    <Stack>
      <Text>Are you sure you want to deactivate your account?</Text>
      {organizationType === OrganizationType.COMPANY ? (
        <Text>
          You will no longer be able to login to your account and view your
          organization's data.
        </Text>
      ) : (
        <Text>
          Deactivating your account will disconnect all social media accounts
          you have connected to Areto.
        </Text>
      )}
      <Group position="right">
        <Button variant="subtle" onClick={() => modals.closeAll()}>
          Cancel
        </Button>
        <Button variant="error" loading={loading} onClick={() => handleClick()}>
          Yes, deactivate
        </Button>
      </Group>
      <ErrorMessage error={error} />
    </Stack>
  );
};
