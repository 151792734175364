import { Link } from 'react-router-dom';

import { Group, Title, Button } from '@mantine/core';

export const Help = () => (
  <Group
    style={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Title order={3}>Need help?</Title>
    <Button
      component={Link}
      to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
    >
      Contact Areto support
    </Button>
  </Group>
);
