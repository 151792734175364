import { Tabs, createStyles } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { ActionLogTable } from './ActionLogTable';
import { setUnresolvedTab, RootState } from 'src/redux/reducers';

const useStyles = createStyles(theme => ({
  navContainer: {
    position: 'relative',
    marginBottom: theme.spacing.lg,
  },
}));

export const ActionLogTabs = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const actionLog = useSelector((state: RootState) => state.actionLog);

  return (
    <Tabs
      onTabChange={(value: string) =>
        dispatch(setUnresolvedTab(value === 'unresolved' ? true : false))
      }
      value={actionLog.unresolvedTab ? 'unresolved' : 'resolved'}
    >
      <div className={classes.navContainer}>
        <Tabs.List>
          <Tabs.Tab key="unresolved-tab" value="unresolved">
            To Action ({actionLog.totals.unresolved})
          </Tabs.Tab>
          <Tabs.Tab key="resolved-tab" value="resolved">
            Moderated ({actionLog.totals.resolved})
          </Tabs.Tab>
        </Tabs.List>
      </div>

      <Tabs.Panel value="unresolved">
        <ActionLogTable
          isUnresolved={true}
          actionLog={actionLog.unresolvedActionLog}
        />
      </Tabs.Panel>
      <Tabs.Panel value="resolved">
        <ActionLogTable
          isUnresolved={false}
          actionLog={actionLog.resolvedActionLog}
        />
      </Tabs.Panel>
    </Tabs>
  );
};
