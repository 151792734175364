import { useState, useEffect, useCallback } from 'react';

import { Response, ActionLogTotalsResponse } from 'src/types';
import { setActionLogTotals } from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

const createParams = (): any => {
  const actionLog = store.getState().actionLog;
  const trackedAccountIds = actionLog.trackedAccountsFilter;

  return {
    start: actionLog.dateRangeFilter!.start,
    end: actionLog.dateRangeFilter!.end,
    ...(trackedAccountIds && trackedAccountIds.length > 0
      ? { tracked_user_ids: trackedAccountIds.join(',') }
      : {}),
    ...(actionLog.resolvedTypeFilter !== 'ALL'
      ? { resolved_statuses: actionLog.resolvedTypeFilter }
      : {}),
    ...(actionLog.abuseTypeFilter !== 'ALL'
      ? { abuse_types: actionLog.abuseTypeFilter }
      : {}),
    ...(actionLog.platformFilter !== 'ALL'
      ? { platforms: actionLog.platformFilter }
      : {}),
  };
};

export const useFetchActionLogTotals = (auto: boolean = true) => {
  // const [data, setData] = useState<ActionLog | null>(null)
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const actionLog = store.getState().actionLog;

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/stats/queue/totals', {
        params: createParams(),
      });
      const data = response.data as Response<ActionLogTotalsResponse>;

      const totals = {
        unresolved: data.response.totals.totalUnresolved,
        resolved: data.response.totals.totalResolved,
      };

      store.dispatch(setActionLogTotals(totals));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (auto) fetchData();
  }, [
    fetchData,
    auto,
    actionLog.dateRangeFilter,
    actionLog.platformFilter,
    actionLog.trackedAccountsFilter,
    actionLog.abuseTypeFilter,
    actionLog.resolvedTypeFilter,
    actionLog.unresolvedTab,
    actionLog.resolvedActionLog.pagination,
    actionLog.unresolvedActionLog.pagination,
  ]);

  const refetch = async () => {
    await fetchData();
  };

  return { error, loading, refetch };
};
