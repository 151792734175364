import { useEffect, useState } from 'react';

import { Stack, TextInput, PasswordInput } from '@mantine/core';
import { Icon } from '@iconify/react';
import { z } from 'zod';

import { FormProps } from './FormProps';

const userSchema = z
  .object({
    name: z.string().trim().nonempty('This field is required. '),
    email: z
      .string()
      .trim()
      .nonempty('This field is required. ')
      .email('Invalid email. '),
    password: z
      .string()
      .trim()
      .nonempty('This field is required. ')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      ),
    confirmPassword: z.string().trim().nonempty('This field is required. '),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match. ',
    path: ['confirmPassword'],
  });

export const UserAccountForm = (props: FormProps) => {
  const { validate, setValidate, values, handleSetValue, setDataValidated } =
    props;
  const [errors, setErrors] = useState<z.ZodError | null>(null);

  useEffect(() => {
    if (validate) {
      const result = userSchema.safeParse(values);

      if (result.success) {
        setDataValidated(true);
      } else {
        setErrors(result.error);
      }

      setValidate(false);
    }
  }, [setErrors, validate, setValidate, values, setDataValidated]);

  return (
    <Stack spacing="sm">
      <TextInput
        name="name"
        required
        label="Name"
        placeholder="Your name"
        mb="sm"
        mt="sm"
        icon={<Icon icon="carbon:user-avatar" />}
        value={values.name}
        onChange={e => handleSetValue('name', e.target.value)}
        error={errors?.formErrors.fieldErrors.name}
      />
      <TextInput
        name="email"
        required
        label="Email"
        placeholder="youremail@awesome.com"
        mb="sm"
        icon={<Icon icon="carbon:email" />}
        value={values.email}
        onChange={e => handleSetValue('email', e.target.value)}
        error={errors?.formErrors.fieldErrors.email}
      />
      <PasswordInput
        name="password"
        required
        label="Password"
        placeholder="Your password"
        mb="sm"
        icon={<Icon icon="carbon:locked" />}
        value={values.password}
        onChange={e => handleSetValue('password', e.target.value)}
        error={errors?.formErrors.fieldErrors.password}
      />
      <PasswordInput
        name="confirmPassword"
        required
        label="Confirm password"
        placeholder="Confirm your password"
        mb="sm"
        icon={<Icon icon="carbon:locked" />}
        value={values.confirmPassword}
        onChange={e => handleSetValue('confirmPassword', e.target.value)}
        error={errors?.formErrors.fieldErrors.confirmPassword}
      />
    </Stack>
  );
};
