import client from '../client';
import { LoginRequest, LoginResponse } from 'src/types';

const login = async (
  data: LoginRequest
): Promise<LoginResponse | undefined> => {
  try {
    const response = await client.post(
      '/accounts/login',
      {
        email: data.email,
        password: data.password,
      },
      {
        params: {
          include_auth_token: true,
        },
      }
    );
    return response.data.response as LoginResponse;
  } catch (e) {
    console.log(e);
  }
};

export default login;
