import { Card, Text, Button, createStyles } from '@mantine/core';
import { Link } from 'react-router-dom';

const useStyles = createStyles(theme => ({
  welcomeCard: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    background: '#353147',
    border: '1px solid #463E61',
    borderRadius: '0px 0px 24px 0px',
    left: '0px',
    top: '0px',
    width: 'fit-content',
    height: '40px',
    paddingLeft: '24px',
    paddingRight: '24px',
    letterSpacing: '0.02em',
    color: theme.colors.secondary[0],
    fontWeight: 500,
  },
  welcomeCardContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 32,
  },
  welcomeCardButton: {
    textAlign: 'center',
    width: '100%',
    height: '44px',
    marginTop: '24px',
    padding: '14px 24px',
    fontSize: '14px',
  },
}));

interface WelcomeCardProps {
  title: string;
  text: string;
  buttonText: string;
  buttonLink: string;
}

export const WelcomeCard = ({
  title,
  text,
  buttonText,
  buttonLink,
}: WelcomeCardProps) => {
  const { classes } = useStyles();

  return (
    <Card shadow="xl" radius="lg" p="xl">
      <Card.Section>
        <Text className={classes.welcomeCard}>{title}</Text>
      </Card.Section>
      <div className={classes.welcomeCardContent}>
        <Text>{text}</Text>
      </div>
      <Button
        component={Link}
        to={buttonLink}
        className={classes.welcomeCardButton}
      >
        {buttonText}
      </Button>
    </Card>
  );
};
