import { Center, Image, Box, createStyles } from '@mantine/core';
import { useLocation, Outlet } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { Anchor } from 'src/components';

const useStyles = createStyles(theme => ({
  loginContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.dark[7],
    '&:before': {
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.28,
      backgroundImage: [
        "url('/flare/dots_top-left.svg')",
        "url('/flare/dots_top-right.svg')",
        "url('/flare/dots_right_x5.svg')",
        "url('/flare/dots_bottom-right.svg')",
        "url('/flare/dots_bottom-left.svg')",
      ].join(', '),
      backgroundRepeat: 'no-repeat',
      backgroundPosition:
        '2px 20%, 85% 2px, calc(100% - 2px) 45%, 80% calc(100% - 2px), 3% calc(100% - 2px)',
      userSelect: 'none',
      pointerEvents: 'none',
    },
  },
}));

export default function AuthPage() {
  const { classes } = useStyles();

  return (
    <Box className={classes.loginContainer}>
      <Anchor to="/">
        <Image
          src="/logo/logo.svg"
          alt="logo"
          width={32}
          style={{ position: 'absolute' }}
        />
      </Anchor>
      <Center style={{ height: '85vh' }}>
        <Box style={{ width: 396 }}>
          <AnimatePresence mode="wait" initial={false}>
            <motion.main
              layout={true}
              key={useLocation().key}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'calc(100%)' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.25 }}
            >
              <Outlet />
            </motion.main>
          </AnimatePresence>
        </Box>
      </Center>
    </Box>
  );
}
