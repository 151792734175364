import { Group, Image } from '@mantine/core';

import { useDispatch, useSelector } from 'react-redux';

import { ComboDatePicker, TrackedUserMultiSelect } from 'src/components';
import { setsFilter, RootState, setDateRangeFilter } from 'src/redux/reducers';

export interface DashboardFiltersProps {
  loadingData: boolean;
}

export const DashboardFilters = (props: DashboardFiltersProps) => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state: RootState) => state.dashboard);

  return (
    <Group>
      {props.loadingData ? (
        <Image
          src="/flare/making-lemonade.gif"
          alt="Empty cup of lemonade"
          width={48}
        />
      ) : (
        <div></div>
      )}
      <TrackedUserMultiSelect
        onChange={v => dispatch(setsFilter(v))}
        initialValues={dashboard.trackedAccountsFilter}
      />

      <ComboDatePicker
        selectedDate={dashboard.dateRangeFilter}
        stateReducer={setDateRangeFilter}
      />
    </Group>
  );
};
