import { useState, useCallback, useEffect } from 'react';
import { Response, TrackedPlatformsStatsResponse } from 'src/types';
import { store } from 'src/redux/store';
import { setPlatformStats } from 'src/redux/reducers';

import client from '../client';

const createParams = (): any => {
  const state = store.getState().dashboard;

  return {
    start: state.dateRangeFilter.start,
    end: state.dateRangeFilter.end,
  };
};

export const useFetchPlatformStats = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const state = store.getState().dashboard;

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/stats/platforms', {
        params: createParams(),
      });

      const data = response.data as Response<TrackedPlatformsStatsResponse>;

      store.dispatch(setPlatformStats(data.response));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.dateRangeFilter, state.trackedAccountsFilter]);

  return { error, loading };
};
