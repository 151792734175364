import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { Tabs, Title } from '@mantine/core';

import {
  Page,
  ModerationPreview,
  Breakdown,
  Accounts,
  Platforms,
  Loading,
  DashboardFilters,
  WelcomeBanner,
} from 'src/components';
import {
  useFetchModerationPreview,
  useFetchStats,
  useFetchTrackedUsersStats,
  useFetchAccount,
  useFetchOrganization,
  useFetchPlatformStats,
} from 'src/api';
import { RootState } from 'src/redux/reducers';

export default function DashboardPage() {
  const [tabKey, setTabKey] = useState(0);
  const [activeTab, setActiveTab] = useState('platforms');

  const accountsTab = useRef<HTMLButtonElement>(null);
  const platformsTab = useRef<HTMLButtonElement>(null);

  const isLoggedIn = useSelector((state: RootState) => state.user.token);
  const dashboard = useSelector((state: RootState) => state.dashboard);
  const trackedAccounts = useSelector(
    (state: RootState) => state.user.organization?.trackedUsers
  );

  const filteredAccounts = useSelector(
    (state: RootState) => state.dashboard.trackedAccountsFilter
  );

  const [errorShown, setErrorShown] = useState(false);
  const { error: errorAccount } = useFetchAccount();
  const { error: errorOrganization, loading: loadingOrganization } =
    useFetchOrganization();
  const { error: errorStats, loading: loadingStats } = useFetchStats();
  const { error: errorPreview, loading: loadingPreview } =
    useFetchModerationPreview();
  const { error: errorAccountStats, loading: loadingAccountStats } =
    useFetchTrackedUsersStats();
  const { error: errorPlatformStats, loading: loadingPlatformStats } =
    useFetchPlatformStats();

  useEffect(() => {
    if (filteredAccounts.length > 0) {
      setActiveTab('accounts');
    } else {
      setActiveTab('platforms');
    }
  }, [filteredAccounts]);

  useEffect(() => {
    if (
      isLoggedIn &&
      !errorShown &&
      (errorAccount ||
        errorOrganization ||
        errorStats ||
        errorPreview ||
        errorAccountStats)
    ) {
      setErrorShown(true);
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      });
    }
  }, [
    errorAccount,
    errorOrganization,
    errorStats,
    errorPreview,
    errorAccountStats,
    errorPlatformStats,
    errorShown,
    isLoggedIn,
  ]);

  if (
    (dashboard.accountStats === undefined ||
      dashboard.moderationPreview === undefined ||
      dashboard.trackedAccountStats === undefined ||
      dashboard.trackedPlatformStats === undefined) &&
    isLoggedIn
  )
    return <Loading />;

  const handleTabChange = (value: string) => {
    setTabKey(prevKey => prevKey + 1);
    setActiveTab(value);
  };

  return (
    <Page
      title="Brand Health Summary"
      right={
        <DashboardFilters
          loadingData={
            loadingPreview ||
            loadingAccountStats ||
            loadingStats ||
            loadingOrganization ||
            loadingPlatformStats
          }
        />
      }
    >
      {(!trackedAccounts || trackedAccounts.length === 0) && <WelcomeBanner />}
      <ModerationPreview />
      <Breakdown />

      <Tabs
        variant="default"
        value={activeTab}
        onTabChange={value => {
          handleTabChange(value as string);
        }}
      >
        <Tabs.List grow>
          <Tabs.Tab value="platforms" ref={platformsTab}>
            <Title order={2}>Platforms</Title>
          </Tabs.Tab>
          <Tabs.Tab value="accounts" ref={accountsTab}>
            <Title order={2}>Accounts</Title>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="platforms" pt="lg">
          <Platforms key={`platforms-${tabKey}`} />
        </Tabs.Panel>

        <Tabs.Panel value="accounts" pt="lg">
          <Accounts key={`accounts-${tabKey}`} />
        </Tabs.Panel>
      </Tabs>
    </Page>
  );
}
