import { useEffect, useState, useCallback } from 'react';

import { modals } from '@mantine/modals';
import { Button } from '@mantine/core';

import { RegisterAccountRequestData } from 'src/types';
import { UserAccountForm, ErrorMessage } from 'src/components';
import { useSendRegisterAccount, useFetchOrganization } from 'src/api';

const INITIAL_STATE: RegisterAccountRequestData = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export const AddUserAccountModal = () => {
  const [values, setValues] = useState(INITIAL_STATE);
  const [validateData, setValidateData] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);

  const { send, loading, error } = useSendRegisterAccount();
  const { fetch } = useFetchOrganization();

  const handleSetValue = (key: string, value: string | number | boolean) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleClick = async () => {
    if (!dataValidated) setValidateData(true);
  };

  const handleSubmit = useCallback(async () => {
    if (dataValidated) {
      setDataValidated(false);

      const success = await send(values);

      if (success) {
        fetch();
        modals.closeAll();
      }
    }
  }, [dataValidated, send, values, fetch]);

  useEffect(() => {
    handleSubmit();
  }, [dataValidated, handleSubmit]);

  return (
    <>
      <UserAccountForm
        values={values}
        handleSetValue={handleSetValue}
        setDataValidated={setDataValidated}
        setValidate={setValidateData}
        validate={validateData}
      />
      <Button fullWidth loading={loading} onClick={handleClick}>
        Submit
      </Button>
      <ErrorMessage error={error} />
    </>
  );
};
