import { useEffect, useState } from 'react';

import { Stack, TextInput } from '@mantine/core';
import { z } from 'zod';

import { RegisterProps } from '../Register';

const adminSchema = z.object({
  offensiveLanguageAutoModerateThreshold: z.number().int().min(1).max(4),
  offensiveLanguageModerationQueueThreshold: z.number().int().min(1).max(4),
  personalAttackAutoModerateThreshold: z.number().int().min(1).max(4),
  personalAttackModerationQueueThreshold: z.number().int().min(1).max(4),
  violentLanguageAutoModerateThreshold: z.number().int().min(1).max(4),
  violentLanguageModerationQueueThreshold: z.number().int().min(1).max(4),
  threatAutoModerateThreshold: z.number().int().min(1).max(4),
  threatModerationQueueThreshold: z.number().int().min(1).max(4),
});

export const StepThreeAdmin = (props: RegisterProps) => {
  const { validate, setValidate, values, handleSetValue, setDataValidated } =
    props;
  const [errors, setErrors] = useState<z.ZodError | null>(null);

  useEffect(() => {
    if (validate) {
      const result = adminSchema.safeParse(values);

      if (result.success) {
        setDataValidated(true);
      } else {
        setErrors(result.error);
      }

      setValidate(false);
    }
  }, [setErrors, validate, setValidate, values, setDataValidated]);

  return (
    <Stack spacing="sm">
      <TextInput
        name="offensiveLanguageAutoModerateThreshold"
        required
        label="Offensive Language AutoModerate Threshold"
        type="number"
        value={values.offensiveLanguageAutoModerateThreshold}
        onChange={e =>
          handleSetValue(
            'offensiveLanguageAutoModerateThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors.offensiveLanguageAutoModerateThreshold
        }
      />
      <TextInput
        name="offensiveLanguageModerationQueueThreshold"
        required
        label="Offensive Language Moderation Queue Threshold"
        type="number"
        value={values.offensiveLanguageModerationQueueThreshold}
        onChange={e =>
          handleSetValue(
            'offensiveLanguageModerationQueueThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors
            .offensiveLanguageModerationQueueThreshold
        }
      />
      <TextInput
        name="personalAttackAutoModerateThreshold"
        required
        label="Personal Attack AutoModerate Threshold"
        type="number"
        value={values.personalAttackAutoModerateThreshold}
        onChange={e =>
          handleSetValue(
            'personalAttackAutoModerateThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors.personalAttackAutoModerateThreshold
        }
      />
      <TextInput
        name="personalAttackModerationQueueThreshold"
        required
        label="Personal Attack Moderation Queue Threshold"
        type="number"
        value={values.personalAttackModerationQueueThreshold}
        onChange={e =>
          handleSetValue(
            'personalAttackModerationQueueThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors.personalAttackModerationQueueThreshold
        }
      />
      <TextInput
        name="violentLanguageAutoModerateThreshold"
        required
        label="Violent Language AutoModerate Threshold"
        type="number"
        value={values.violentLanguageAutoModerateThreshold}
        onChange={e =>
          handleSetValue(
            'violentLanguageAutoModerateThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors.violentLanguageAutoModerateThreshold
        }
      />
      <TextInput
        name="violentLanguageModerationQueueThreshold"
        required
        label="Violent Language Moderation Queue Threshold"
        type="number"
        value={values.violentLanguageModerationQueueThreshold}
        onChange={e =>
          handleSetValue(
            'violentLanguageModerationQueueThreshold',
            Number(e.target.value)
          )
        }
        error={
          errors?.formErrors.fieldErrors.violentLanguageModerationQueueThreshold
        }
      />
      <TextInput
        name="threatAutoModerateThreshold"
        required
        label="Threat AutoModerate Threshold"
        type="number"
        value={values.threatAutoModerateThreshold}
        onChange={e =>
          handleSetValue('threatAutoModerateThreshold', Number(e.target.value))
        }
        error={errors?.formErrors.fieldErrors.threatAutoModerateThreshold}
      />
      <TextInput
        name="threatModerationQueueThreshold"
        required
        label="Threat Moderation Queue Threshold"
        type="number"
        value={values.threatModerationQueueThreshold}
        onChange={e =>
          handleSetValue(
            'threatModerationQueueThreshold',
            Number(e.target.value)
          )
        }
        error={errors?.formErrors.fieldErrors.threatModerationQueueThreshold}
      />
    </Stack>
  );
};
