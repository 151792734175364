import { useState, useEffect, useCallback } from 'react';

import {
  Response,
  ActionLogEntry,
  ModerationResolveType,
  AbuseType,
  ActionLogResponse,
} from 'src/types';
import {
  setUnresolvedActionLogEntries,
  setResolvedActionLogEntries,
  setUnresolvedActionLogTotalEntries,
  setResolvedActionLogTotalEntries,
  setUnresolvedActionLogTotalPages,
  setResolvedActionLogTotalPages,
} from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

const createParams = (): any => {
  const actionLog = store.getState().actionLog;
  const isUnresolved = actionLog.unresolvedTab;
  const trackedAccountIds = actionLog.trackedAccountsFilter;
  const pagination = isUnresolved
    ? actionLog.unresolvedActionLog.pagination
    : actionLog.resolvedActionLog.pagination;
  const resolvedStatuses = isUnresolved
    ? ['UNRESOLVED']
    : actionLog.resolvedTypeFilter === 'ALL'
      ? []
      : [actionLog.resolvedTypeFilter];

  return {
    start: actionLog.dateRangeFilter!.start,
    end: actionLog.dateRangeFilter!.end,
    page: pagination.currentPage,
    per_page: pagination.perPage,
    sort_by: actionLog.sortModel.sort_by,
    order: actionLog.sortModel.sort_order,
    ...(trackedAccountIds && trackedAccountIds.length > 0
      ? { tracked_user_ids: trackedAccountIds.join(',') }
      : {}),
    ...(resolvedStatuses && resolvedStatuses.length > 0
      ? { resolved_statuses: resolvedStatuses.join(',') }
      : {}),
    ...(actionLog.abuseTypeFilter !== 'ALL'
      ? { abuse_types: actionLog.abuseTypeFilter }
      : {}),
    ...(actionLog.platformFilter !== 'ALL'
      ? { platforms: actionLog.platformFilter }
      : {}),
  };
};

const parseActionLogEntries = (
  data: Response<ActionLogResponse>
): ActionLogEntry[] => {
  return data.response.entries.map(entry => {
    return {
      ...entry,
      abuseTags: entry.abuseTags.map(tag => AbuseType[tag]),
      resolution: {
        ...entry.resolution,
        status: ModerationResolveType[entry.resolution.status],
      },
    };
  });
};

const parseData = (data: Response<ActionLogResponse>) => {
  const actionLog = store.getState().actionLog;
  const parsedEntries = parseActionLogEntries(data);

  if (actionLog.unresolvedTab) {
    store.dispatch(setUnresolvedActionLogEntries(parsedEntries));
    store.dispatch(
      setUnresolvedActionLogTotalEntries(data.response.totalEntries)
    );
    store.dispatch(setUnresolvedActionLogTotalPages(data.response.totalPages));
  } else {
    store.dispatch(setResolvedActionLogEntries(parsedEntries));
    store.dispatch(
      setResolvedActionLogTotalEntries(data.response.totalEntries)
    );
    store.dispatch(setResolvedActionLogTotalPages(data.response.totalPages));
  }
};

export const useFetchActionLog = (auto: boolean = true) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const actionLog = store.getState().actionLog;

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/moderation/queue', {
        params: createParams(),
      });
      const data = response.data as Response<ActionLogResponse>;
      parseData(data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (auto) fetchData();
  }, [
    fetchData,
    auto,
    actionLog.dateRangeFilter,
    actionLog.platformFilter,
    actionLog.trackedAccountsFilter,
    actionLog.resolvedTypeFilter,
    actionLog.abuseTypeFilter,
    actionLog.sortModel,
    actionLog.unresolvedTab,
    actionLog.resolvedActionLog.pagination,
    actionLog.unresolvedActionLog.pagination,
  ]);

  const refetch = async () => {
    await fetchData();
  };

  return { error, loading, refetch };
};
