import { Anchor as MantineAnchor, TextProps } from '@mantine/core';

import { Link, LinkProps } from 'react-router-dom';
import { FunctionComponent, ReactNode } from 'react';

export interface AnchorProps extends Omit<LinkProps, 'color'>, TextProps {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
}

export const Anchor: FunctionComponent<AnchorProps> = props => {
  const { iconLeft, iconRight, ...linkProps } = props;
  return (
    <MantineAnchor
      {...linkProps}
      component={Link}
      sx={theme => ({
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
        color: theme.colors.primary[5],
        '&:hover': {
          color: theme.colors.primary[6],
        },
      })}
    >
      {props.iconLeft}
      {props.children}
      {props.iconRight}
    </MantineAnchor>
  );
};
