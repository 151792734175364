import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getPresetDateRange } from 'src/utils/date';
import {
  ActionLog,
  ActionLogEntry,
  DateRange,
  Pagination,
  ActionLogTotals,
  ModerationActionType,
  DatePreset,
  ActionLogSortModel,
} from 'src/types';

export interface ActionLogState {
  loaded: boolean;
  dateRangeFilter: DateRange;
  trackedAccountsFilter: number[];
  abuseTypeFilter: string;
  platformFilter: string;
  resolvedTypeFilter: string;
  sortModel: ActionLogSortModel;
  unresolvedTab: boolean;
  unresolvedActionLog: ActionLog;
  resolvedActionLog: ActionLog;
  totals: ActionLogTotals;
}

const initialState = {
  loaded: false,
  dateRangeFilter: getPresetDateRange('last24Hours' as DatePreset),
  trackedAccountsFilter: [],
  abuseTypeFilter: 'ALL',
  platformFilter: 'ALL',
  resolvedTypeFilter: 'ALL',
  unresolvedTab: true,
  sortModel: { sort_by: 'commentPosted', sort_order: 'desc' },
  unresolvedActionLog: {
    entries: [],
    totalEntries: 0,
    totalPages: 1,
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    selectedIds: [],
  },
  resolvedActionLog: {
    entries: [],
    totalEntries: 0,
    totalPages: 1,
    pagination: {
      currentPage: 1,
      perPage: 10,
    },
    selectedIds: [],
  },
  totals: {
    unresolved: 0,
    resolved: 0,
  },
  actionModal: {
    open: false,
    action: ModerationActionType.ARCHIVE,
    singleAction: true,
  },
  commentModal: {
    open: false,
  },
} as ActionLogState;

const actionLogSlice = createSlice({
  name: 'actionLog',
  initialState,
  reducers: {
    setActionLogDateRangeFilter(state, action: PayloadAction<DateRange>) {
      state.dateRangeFilter = action.payload;
      state.unresolvedActionLog.pagination =
        initialState.unresolvedActionLog.pagination;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setActionLogsFilter(state, action: PayloadAction<string[]>) {
      state.trackedAccountsFilter = action.payload.map(v => Number(v));
      state.unresolvedActionLog.pagination =
        initialState.unresolvedActionLog.pagination;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setActionLogPlatformFilter(state, action: PayloadAction<string>) {
      state.platformFilter = action.payload;
      state.unresolvedActionLog.pagination =
        initialState.unresolvedActionLog.pagination;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setActionLogAbuseTypeFilter(state, action: PayloadAction<string>) {
      state.abuseTypeFilter = action.payload;
      state.unresolvedActionLog.pagination =
        initialState.unresolvedActionLog.pagination;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setActionLogResolvedTypeFilter(state, action: PayloadAction<string>) {
      state.resolvedTypeFilter = action.payload;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setActionLogSortModel(state, action: PayloadAction<any>) {
      state.sortModel = action.payload;

      state.unresolvedActionLog.pagination =
        initialState.unresolvedActionLog.pagination;
      state.resolvedActionLog.pagination =
        initialState.resolvedActionLog.pagination;
    },
    setUnresolvedTab(state, action: PayloadAction<boolean>) {
      state.unresolvedTab = action.payload;
    },
    setUnresolvedActionLogEntries(
      state,
      action: PayloadAction<ActionLogEntry[]>
    ) {
      state.unresolvedActionLog.entries = action.payload;
      state.loaded = true;
    },
    setUnresolvedActionLogTotalEntries(state, action: PayloadAction<number>) {
      state.unresolvedActionLog.totalEntries = action.payload;
    },
    setUnresolvedActionLogTotalPages(state, action: PayloadAction<number>) {
      state.unresolvedActionLog.totalPages = action.payload;
    },
    setResolvedActionLogEntries(
      state,
      action: PayloadAction<ActionLogEntry[]>
    ) {
      state.resolvedActionLog.entries = action.payload;
    },
    setResolvedActionLogTotalEntries(state, action: PayloadAction<number>) {
      state.resolvedActionLog.totalEntries = action.payload;
    },
    setResolvedActionLogTotalPages(state, action: PayloadAction<number>) {
      state.resolvedActionLog.totalPages = action.payload;
    },
    setUnresolvedActionLogPagination(state, action: PayloadAction<Pagination>) {
      state.unresolvedActionLog.pagination = action.payload;
    },
    setResolvedActionLogPagination(state, action: PayloadAction<Pagination>) {
      state.resolvedActionLog.pagination = action.payload;
    },
    setUnresolvedActionLogSelectedIds(state, action: PayloadAction<number[]>) {
      state.unresolvedActionLog.selectedIds = action.payload;
    },
    setResolvedActionLogSelectedIds(state, action: PayloadAction<number[]>) {
      state.resolvedActionLog.selectedIds = action.payload;
    },
    setActionLogTotals(state, action: PayloadAction<ActionLogTotals>) {
      state.totals = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState;
    });
  },
});

export const {
  setActionLogDateRangeFilter,
  setActionLogsFilter,
  setActionLogPlatformFilter,
  setActionLogAbuseTypeFilter,
  setActionLogResolvedTypeFilter,
  setActionLogSortModel,
  setUnresolvedTab,
  setUnresolvedActionLogEntries,
  setResolvedActionLogEntries,
  setUnresolvedActionLogTotalEntries,
  setResolvedActionLogTotalEntries,
  setUnresolvedActionLogTotalPages,
  setResolvedActionLogTotalPages,
  setUnresolvedActionLogPagination,
  setResolvedActionLogPagination,
  setUnresolvedActionLogSelectedIds,
  setResolvedActionLogSelectedIds,
  setActionLogTotals,
} = actionLogSlice.actions;

export default actionLogSlice.reducer;
