import { createTheme } from '@mui/material/styles';

import { theme } from './theme';

export const MuiTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 1,
          borderColor: theme.colors.dark[4],
          borderStyle: 'solid',
          borderRadius: 16,
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.35)',
          backgroundColor: theme.colors.dark[6],
          fontFamily: 'Outfit',
          fontSize: 14,
          padding: 30,
          color: theme.colors.dark[0],
        },
        columnHeaders: {
          color: theme.colors.dark[2],
          fontWeight: 'bold',
        },
        columnHeader: {
          '&:focus': {
            outline: 'none !important',
          },
          '&:focus-within': {
            outline: 'none !important',
          },
        },
        row: {
          fontWeight: 300,
          fontFamily: 'Outfit',
          '&:hover': {
            backgroundColor: theme.colors.dark[4],
          },
          '&.Mui-selected': {
            backgroundColor: theme.colors.dark[4],
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.colors.dark[4],
          },
        },
        cell: {
          '&:focus': {
            outline: 'none !important',
          },
          '&:focus-within': {
            outline: 'none !important',
          },
        },
        withBorderColor: {
          borderColor: theme.colors.dark[4],
        },
        columnSeparator: {
          color: theme.colors.dark[4],
        },
        iconButtonContainer: {
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.colors.dark[4],
          },
          color: theme.colors.dark[2],
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: theme.colors.dark[2],
          fontFamily: 'Outfit',
          textTransform: 'capitalize',
        },
        displayedRows: {
          textTransform: 'lowercase',
        },
        menuItem: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Outfit',
          fontSize: 14,
          color: theme.colors.dark[9],
          backgroundColor: theme.colors.light[1],
          '&:hover': {
            backgroundColor: theme.colors.light[2],
          },
          '&.Mui-selected': {
            backgroundColor: theme.colors.light[2],
            '&:hover': {
              backgroundColor: theme.colors.light[2],
            },
            '&:focus': {
              backgroundColor: theme.colors.light[2],
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: theme.colors.light[1],
          color: theme.colors.dark[9],
          borderRadius: 10,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.colors.dark[9],
          fontSize: 16,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.colors.dark[4],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.colors.dark[4],
          '&.Mui-checked': {
            color: theme.colors.primary[5],
          },
        },
      },
    },
  },
});
