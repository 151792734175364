import * as _ from 'lodash';
import { useState, useCallback, useEffect } from 'react';

import {
  Response,
  AllAccountStats,
  AccountStats,
  HistogramInterval,
} from 'src/types';
import { SOCIAL_PLATFORMS } from 'src/utils/constants';
import { createEmptyPlatformStats } from 'src/utils/emptyStats';
import { store } from 'src/redux/store';
import { setAccountStats } from 'src/redux/reducers';

import client from '../client';

/**
 * Fill in the account stats, if there are none, for a specific platform.
 * Platforms will always be returned in alphabetical.
 *
 * Only platforms with tracked users will be returned, if there are no tracked
 * users, then all platforms will be returned.
 *
 * All Areto Scores are set to 1, and the comment totals are 0. Only the
 * current account stats will be returned with intervals, that are also
 * set to the defaults.
 *
 * @param stats the account stats to check for missing platforms
 * @param start the start date as an ISOString
 * @param end the end datetime as an ISOString
 * @param interval the type of interval (hour, day)
 * @param isCurrent whether this is the current account stats
 * @returns account stats with all platforms
 */
const fillInEmptyPlatformStats = (
  stats: AccountStats,
  start: string,
  end: string,
  interval: HistogramInterval,
  isCurrent: boolean
): AccountStats => {
  const state = store.getState();

  const TRACKED_PLATFORMS: string[] =
    state.user.trackedPlatforms && state.user.trackedPlatforms.length > 0
      ? state.user.trackedPlatforms.slice().sort()
      : SOCIAL_PLATFORMS.slice().sort();

  const newStats = _.cloneDeep(stats);

  if (newStats.platforms.length === TRACKED_PLATFORMS.length) {
    newStats.platforms = newStats.platforms.sort((a, b) =>
      a.key.localeCompare(b.key)
    );
    return newStats;
  }

  TRACKED_PLATFORMS.forEach((platform, index) => {
    if (!newStats.platforms.find(p => p.key === platform)) {
      let defaultStats = createEmptyPlatformStats(
        platform,
        start,
        end,
        interval,
        isCurrent
      );
      newStats.platforms.push(defaultStats);
    }
  });

  newStats.platforms = newStats.platforms.sort((a, b) =>
    a.key.localeCompare(b.key)
  );

  return newStats;
};

const parseData = (data: AllAccountStats): AllAccountStats => {
  const state = store.getState().dashboard;

  return {
    current: fillInEmptyPlatformStats(
      data.current,
      state.dateRangeFilter.start,
      state.dateRangeFilter.end,
      state.dateRangeFilter.interval,
      true
    ),
    previous: fillInEmptyPlatformStats(
      data.previous,
      state.dateRangeFilter.start,
      state.dateRangeFilter.end,
      state.dateRangeFilter.interval,
      false
    ),
  } as AllAccountStats;
};

const createParams = (): any => {
  const state = store.getState().dashboard;
  const trackedAccountIds = state.trackedAccountsFilter;

  return {
    start: state.dateRangeFilter.start,
    end: state.dateRangeFilter.end,
    interval: state.dateRangeFilter.interval,
    include_previous: true,
    ...(trackedAccountIds && trackedAccountIds.length > 0
      ? { tracked_user_ids: trackedAccountIds.join(',') }
      : {}),
  };
};

export const useFetchStats = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const state = store.getState();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/stats/dashboard', {
        params: createParams(),
      });
      const data = response.data as Response<AllAccountStats>;
      const parsedData = parseData(data.response);

      store.dispatch(setAccountStats(parsedData));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    fetchData,
    state.dashboard.dateRangeFilter,
    state.dashboard.trackedAccountsFilter,
    state.user.trackedPlatforms,
  ]);

  return { error, loading };
};
