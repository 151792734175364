import { useState, useCallback, useEffect } from 'react';

import { Response, ModerationPreviewResponse, AbuseType } from 'src/types';
import { setModerationPreview } from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

const parseData = (data: Response<ModerationPreviewResponse>) => {
  return {
    toActionAbuseTags: data.response.abuseTagsUnresolved.map(
      tag => AbuseType[tag]
    ),
    moderatedAbuseTags: data.response.abuseTagsResolved.map(
      tag => AbuseType[tag]
    ),
    toActionTotal: data.response.totalUnresolved,
    moderatedTotal: data.response.totalResolved,
  };
};

const createParams = () => {
  const state = store.getState().dashboard;
  const trackedAccountIds = state.trackedAccountsFilter;

  return {
    start: state.dateRangeFilter.start,
    end: state.dateRangeFilter.end,
    ...(trackedAccountIds && trackedAccountIds.length > 0
      ? { tracked_user_ids: trackedAccountIds.join(',') }
      : {}),
  };
};

export const useFetchModerationPreview = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const state = store.getState().dashboard;

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/stats/preview', {
        params: createParams(),
      });
      const data = response.data as Response<ModerationPreviewResponse>;
      const parsedData = parseData(data);

      store.dispatch(setModerationPreview(parsedData));
    } catch (error: any) {
      setError(error.message);

      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.dateRangeFilter, state.trackedAccountsFilter]);

  return { error, loading };
};
