import { Group, Select, Image } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { SOCIAL_PLATFORMS } from 'src/utils/constants';
import { ComboDatePicker, TrackedUserMultiSelect } from 'src/components';
import { PlatformTypeLabels } from 'src/types';
import {
  RootState,
  setActionLogDateRangeFilter,
  setActionLogPlatformFilter,
  setActionLogsFilter,
} from 'src/redux/reducers';

export interface ActionLogFiltersProps {
  loadingData: boolean;
}

export const ActionLogFilters = (props: ActionLogFiltersProps) => {
  const dispatch = useDispatch();

  const trackedPlatforms = useSelector(
    (state: RootState) => state.user.trackedPlatforms
  );
  const actionLog = useSelector((state: RootState) => state.actionLog);

  const TRACKED_PLATFORMS =
    trackedPlatforms && trackedPlatforms.length > 0
      ? trackedPlatforms
      : SOCIAL_PLATFORMS;

  return (
    <Group>
      {props.loadingData && actionLog.loaded ? (
        <Image
          src="/flare/making-lemonade.gif"
          alt="Empty cup of lemonade"
          width={48}
        />
      ) : (
        <div></div>
      )}
      <TrackedUserMultiSelect
        onChange={v => dispatch(setActionLogsFilter(v))}
        initialValues={actionLog.trackedAccountsFilter}
      />
      <Select
        data={Object.entries(PlatformTypeLabels)
          .filter(([key, value]) => {
            if (TRACKED_PLATFORMS.includes(key) || key === 'ALL') return true;
            return false;
          })
          .map(([key, value]) => ({
            label: value,
            value: key,
          }))}
        value={actionLog.platformFilter}
        onChange={v => dispatch(setActionLogPlatformFilter(v!))}
      />
      <ComboDatePicker
        selectedDate={actionLog.dateRangeFilter}
        stateReducer={setActionLogDateRangeFilter}
      />
    </Group>
  );
};
