import { Title, Grid, Space } from '@mantine/core';
import { useSelector } from 'react-redux';

import { CommentsCard } from './CommentsCard';
import { AretoScoreChartCard } from './AretoScoreChartCard';
import { RootState } from 'src/redux/rootReducer';
import { TrackedUserStats } from 'src/types';

export interface PlatformStats {
  platform: string;
  totalComments: number;
  abusiveComments: number;
}

const aggregatePlatformStats = (
  userStats: TrackedUserStats[]
): PlatformStats[] => {
  const platformStatsMap: { [key: string]: PlatformStats } = {};

  userStats.forEach(userStat => {
    const { platform, totalComments, abusiveComments } = userStat;

    if (!platformStatsMap[platform]) {
      platformStatsMap[platform] = {
        platform,
        totalComments,
        abusiveComments,
      };
    } else {
      platformStatsMap[platform].totalComments += totalComments;
      platformStatsMap[platform].abusiveComments += abusiveComments;
    }
  });

  // Convert the map to an array of PlatformStats
  return Object.values(platformStatsMap);
};

export const Breakdown = () => {
  const userStats = useSelector(
    (state: RootState) => state.dashboard.trackedAccountStats
  );

  const platformsStats = aggregatePlatformStats(
    userStats?.trackedUsers.current || []
  );

  return (
    <section className="page-section">
      <header>
        <Title order={2}>Breakdown</Title>
      </header>
      <Grid gutter="lg" grow>
        <Grid.Col span={6}>
          <CommentsCard
            title="Unwelcome Comments"
            isNegative={true}
            numComments={platformsStats
              .map(platform => platform.abusiveComments)
              .reduce((a, b) => a + b, 0)}
            comments={platformsStats.map(platform => {
              return {
                platform: platform.platform,
                numComments: platform.abusiveComments,
              };
            })}
          />
          <Space h="md" />
          <CommentsCard
            title="Comments Tracked"
            isNegative={false}
            numComments={platformsStats
              .map(platform => platform.totalComments)
              .reduce((a, b) => a + b, 0)}
            comments={platformsStats.map(platform => {
              return {
                platform: platform.platform,
                numComments: platform.totalComments,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <AretoScoreChartCard />
        </Grid.Col>
      </Grid>
    </section>
  );
};
