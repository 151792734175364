import { notifications } from '@mantine/notifications';

import { setOrganization } from 'src/redux/reducers';
import { store } from 'src/redux/store';

import client from '../client';

export const useSendDisconnectTrackedUser = () => {
  const state = store.getState();
  const organization = state.user.organization;

  const updateState = (trackedUserId: number, isActive: boolean = false) => {
    if (organization) {
      const platformId = organization.trackedUsers.find(
        trackedUser => trackedUser.id === trackedUserId
      )?.platformId;

      // Disconnecting FB account also disconnects IG account.
      // FACEBOOK.platformId === INSTAGRAM.secondaryPlatformId
      const trackedUsers = organization.trackedUsers.map(trackedUser => {
        if (
          trackedUser.platformId === platformId ||
          trackedUser.secondaryPlatformId === platformId
        ) {
          return {
            ...trackedUser,
            isActive,
          };
        }

        return trackedUser;
      });

      store.dispatch(
        setOrganization({
          ...organization,
          trackedUsers,
        })
      );
    }
  };

  const handleSuccess = () => {
    notifications.show({
      title: 'Social Media Account Disconnected',
      message: 'This account has been successfully disconnected from Areto.',
      color: 'teal',
    });
  };

  const handleError = (trackedUserId: number) => {
    notifications.show({
      title: 'Uh-oh! Something Went Wrong',
      message:
        'We were unable to complete this action. Please try again later.',
    });

    updateState(trackedUserId, true);
  };

  const send = async (trackedUserId: number) => {
    updateState(trackedUserId);

    try {
      const response = await client.delete(`/platforms/user/${trackedUserId}`);

      if (response.status === 204) {
        handleSuccess();
      }
    } catch (error: any) {
      handleError(trackedUserId); // error.message
    }
  };

  return { send };
};
