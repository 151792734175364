import { FunctionComponent } from 'react';
import { Badge, createStyles, Group, Text } from '@mantine/core';

import { AbuseTypeLabels } from 'src/types';

const useStyles = createStyles(theme => ({
  group: {
    rowGap: 8,
    columnGap: 8,
  },
  badge__root: {
    fontSize: 14,
    paddingBottom: 7,
    borderRadius: 8,
    border: 0,
  },
}));

export interface AbuseTagsProps {
  tags: string[];
  expanded?: boolean;
  numToShow?: number;
  isDark?: boolean;
}

export const AbuseTags: FunctionComponent<AbuseTagsProps> = props => {
  const { tags, expanded, numToShow = 1, isDark } = props;
  const { classes } = useStyles();
  const tagsToRender = expanded ? tags : tags.slice(0, numToShow);
  return (
    <Group className={classes.group}>
      {tagsToRender.map(t => (
        <Badge
          className={classes['badge__root']}
          key={t}
          size="lg"
          style={{
            background: isDark
              ? 'rgba(0, 0, 0, 0.6)'
              : 'rgba(255, 203, 0, 0.06)',
          }}
        >
          {AbuseTypeLabels[t]}
        </Badge>
      ))}
      {!expanded && tags.length > numToShow && (
        <Text span color="yellow">
          +{tags.length - numToShow}
        </Text>
      )}
    </Group>
  );
};
