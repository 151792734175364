import { useEffect } from 'react';

import { Card, Title, Text, Group, Select, Stack } from '@mantine/core';

import { LanguageTypeLabels, Account } from 'src/types';
import { useFetchAccount, useSendUpdateAccount } from 'src/api';

interface MyAccountProps {
  profile: Account;
}

export const MyAccount = ({ profile }: MyAccountProps) => {
  const { fetch: fetchAccount } = useFetchAccount({
    showErrorNotification: true,
  });
  const { send: sendUpdateSettings } = useSendUpdateAccount();

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  return (
    <section className="page-section">
      <header>
        <Title order={2}>My Account</Title>
      </header>
      <Card style={{ overflow: 'visible' }}>
        <Stack spacing={10}>
          <Group position="apart">
            <Text>Name</Text>
            <Text>{profile?.name}</Text>
          </Group>
          <Group position="apart">
            <Text>Email</Text>
            <Text>{profile?.email}</Text>
          </Group>
        </Stack>
        <Group position="apart" mt={8}>
          <Text>Translation Language</Text>
          <Select
            data={Object.entries(LanguageTypeLabels).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
            value={profile?.translationLanguage || 'en'}
            onChange={v => sendUpdateSettings(v as string)}
          />
        </Group>
      </Card>
    </section>
  );
};
