import { Card } from '@mantine/core';

import { Empty, Anchor } from 'src/components';

export const NoTrackedUsers = () => {
  return (
    <Card>
      <Empty
        title="Nothing here...yet"
        message={
          <>
            You aren’t tracking any accounts on social media yet. Go to{' '}
            <Anchor to="/settings">Settings</Anchor> to link your accounts and
            get started.
          </>
        }
      />
    </Card>
  );
};
