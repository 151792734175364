import { HistogramInterval } from 'src/types';

export enum DatePreset {
  custom = 'custom',
  today = 'today',
  thisWeek = 'thisWeek',
  thisMonth = 'thisMonth',
  thisYear = 'thisYear',
  last24Hours = 'last24Hours',
  last3Days = 'last3Days',
  last7Days = 'last7Days',
  last14Days = 'last14Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
}

export const DatePresetLabel: Record<DatePreset, string> = {
  [DatePreset.custom]: 'Custom',
  [DatePreset.today]: 'Today',
  [DatePreset.thisWeek]: 'This week',
  [DatePreset.thisMonth]: 'This month',
  [DatePreset.thisYear]: 'This year',
  [DatePreset.last24Hours]: 'Last 24 hours',
  [DatePreset.last3Days]: 'Last 3 days',
  [DatePreset.last7Days]: 'Last 7 days',
  [DatePreset.last14Days]: 'Last 14 days',
  [DatePreset.last30Days]: 'Last 30 days',
  [DatePreset.last90Days]: 'Last 90 days',
};

export interface DateRange {
  key: DatePreset;
  value: string;
  label: string;
  start: string;
  end: string;
  /** Default interval to use when retrieving temporal data. */
  interval: HistogramInterval;
}
