import { Button, Card, Title, Text, Group, Space, Center } from '@mantine/core';
import { Link } from 'react-router-dom';

import { useFetchAuthLink } from 'src/api';
import { PlatformType } from 'src/types';

export const AuthLinks = () => {
  const { loading: metaLoading, data: metaAuthLink } = useFetchAuthLink(
    PlatformType.FACEBOOK
  );
  const { loading: tiktokLoading, data: tiktokAuthLink } = useFetchAuthLink(
    PlatformType.TIKTOK
  );
  const { loading: youtubeLoading, data: youtubeAuthLink } = useFetchAuthLink(
    PlatformType.YOUTUBE
  );

  return (
    <section className="page-section">
      <header>
        <Title order={2}>Connect Social Media Accounts</Title>
      </header>
      <Card>
        <Group position="apart" mb={8}>
          <Text>Facebook & Instagram</Text>
          <Button
            loading={metaLoading}
            disabled={metaLoading && !metaAuthLink}
            component={Link}
            to={metaAuthLink || ''}
            rel="noopener noreferrer"
            target="_blank"
          >
            Connect
          </Button>
        </Group>
        <Group position="apart" mb={8}>
          <Text>TikTok</Text>
          <Button
            loading={tiktokLoading}
            disabled={tiktokLoading && !tiktokAuthLink}
            component={Link}
            to={tiktokAuthLink || ''}
            rel="noopener noreferrer"
            target="_blank"
          >
            Connect
          </Button>
        </Group>
        <Group position="apart" mb={8}>
          <Text>YouTube</Text>
          <Button
            loading={youtubeLoading}
            disabled={youtubeLoading && !youtubeAuthLink}
            component={Link}
            to={youtubeAuthLink || ''}
            rel="noopener noreferrer"
            target="_blank"
          >
            Connect
          </Button>
        </Group>
        <Space h="17px" />
        <Center>
          <Button
            variant="info"
            component={Link}
            to={process.env.REACT_APP_ONBOARDING_LINK!}
            rel="noopener noreferrer"
            target="_blank"
          >
            how to: connect accounts
          </Button>
        </Center>
      </Card>
    </section>
  );
};
