import { useState } from 'react';
import { Button, SimpleGrid, Input, Popover, Group } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Icon } from '@iconify/react';

import { useDispatch } from 'react-redux';

import { getPresetDateRange, getCalendarDateRange } from 'src/utils/date';
import { DatePreset, DatePresetLabel, DateRange } from 'src/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface ComboDatePickerProps {
  selectedDate: DateRange;
  stateReducer: ActionCreatorWithPayload<DateRange, string>;
}

export const ComboDatePicker = ({
  selectedDate,
  stateReducer,
}: ComboDatePickerProps) => {
  const [opened, setOpened] = useState(false);
  const [dateRangeLabel, setDateRangeLabel] = useState<string>(
    DatePresetLabel[selectedDate.key]
  );
  const [calendarDateRange, setCalendarDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const dispatch = useDispatch();

  const handleCalendarSelect = (value: [Date | null, Date | null]) => {
    if (value[0] === null || value[1] === null) {
      setCalendarDateRange([null, null]);
      return;
    }

    const dateRange = getCalendarDateRange(value as [Date, Date]);

    dispatch(stateReducer(dateRange));
    setCalendarDateRange([new Date(dateRange.start), new Date(dateRange.end)]);
    setDateRangeLabel(dateRange.label);
  };

  const handlePresetSelect = (key: DatePreset) => {
    const dateRange = getPresetDateRange(key as DatePreset);

    if (dateRange) {
      dispatch(stateReducer(dateRange));
      setDateRangeLabel(dateRange.label);
      setCalendarDateRange([
        new Date(dateRange.start),
        new Date(dateRange.end),
      ]);
    }
  };

  return (
    <Popover
      opened={opened}
      width={300}
      onClose={() => setOpened(false)}
      position="bottom-end"
      withArrow
    >
      <Popover.Target>
        <Input
          icon={<Icon icon="mdi:calendar" />}
          readOnly
          onClick={() => setOpened(o => !o)}
          value={dateRangeLabel}
          sx={{
            input: {
              cursor: 'pointer',
            },
          }}
        ></Input>
      </Popover.Target>
      <Popover.Dropdown>
        <Group position="center" my="lg">
          <DatePicker
            type="range"
            allowSingleDateInRange
            firstDayOfWeek={0}
            defaultDate={new Date()}
            minDate={new Date(2024, 2, 12)}
            maxDate={new Date()}
            value={calendarDateRange}
            onChange={handleCalendarSelect}
          />
        </Group>

        <hr />

        <SimpleGrid cols={2} spacing={16} m="md" mt="xl">
          {Object.entries(DatePreset)
            .filter(([key]) => key !== 'custom')
            .map(([key, value]) => (
              <Button
                key={key}
                disabled={selectedDate.key === value}
                compact
                fullWidth
                onClick={() => handlePresetSelect(value)}
              >
                {DatePresetLabel[value]}
              </Button>
            ))}
        </SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  );
};
