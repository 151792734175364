import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import { forwardRef } from 'react';

export const TextInput = forwardRef((props: TextInputProps, ref: any) => {
  return (
    <MantineTextInput
      {...props}
      ref={ref}
      // wrapping labels in span tag so style overrides can target text nodes
      // See ./overrides.ts
      label={<span>{props.label}</span>}
    />
  );
});
