export enum ModerationResolveType {
  ARCHIVED = 'ARCHIVED',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
  EXTERNAL_MODERATED = 'EXTERNAL_MODERATED',
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  HIDDEN = 'HIDDEN',
  OTHER = 'OTHER',
  REPLIED = 'REPLIED',
  REPORTED = 'REPORTED',
  UNRESOLVED = 'UNRESOLVED',
}

export const ModerationResolveTypeLabels: Record<string, string> = {
  ARCHIVED: 'Archived',
  BLOCKED: 'Blocked',
  DELETED: 'Deleted',
  EXTERNAL_MODERATED: 'Externally Moderated',
  FALSE_POSITIVE: 'Not Abusive',
  HIDDEN: 'Hidden',
  OTHER: 'Other',
  REPLIED: 'Replied',
  REPORTED: 'Reported',
  UNRESOLVED: 'Unresolved',
};

export const ModerationResolveTypeSelect: Record<string, string> = {
  ALL: 'All Statuses',
  HIDDEN: 'Hidden',
  DELETED: 'Deleted',
  EXTERNAL_MODERATED: 'Externally Moderated',
  FALSE_POSITIVE: 'Not Abusive',
  ARCHIVED: 'Archived',
};
