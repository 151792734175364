import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { modals } from '@mantine/modals';
import { Card, Title, Text, Stack, Button, createStyles } from '@mantine/core';

import { RootState } from 'src/redux/rootReducer';
import { AccessLevel, OrganizationType } from 'src/types';
import {
  DeactivateMyAccountModal,
  DeactivateOrganizationModal,
} from 'src/components';

const openDeactivateAccountModal = (
  accountId: number,
  organizationType: OrganizationType,
  navigate: () => void
) =>
  modals.open({
    title: 'Deactivate User Account',
    centered: true,
    children: (
      <DeactivateMyAccountModal
        accountId={accountId}
        organizationType={organizationType}
        navigateDeactivatedPage={navigate}
      />
    ),
  });

const openDeactivateOrganizationModal = (navigate: () => void) =>
  modals.open({
    title: 'Deactivate Organization',
    centered: true,
    children: (
      <DeactivateOrganizationModal navigateDeactivatedPage={navigate} />
    ),
  });

interface DeactivateMessageProps {
  title: string;
  content: string;
  buttonText: string;
  submit: () => void;
}

const DeactivateMessage = ({
  title,
  content,
  buttonText,
  submit,
}: DeactivateMessageProps) => {
  return (
    <Stack spacing={8}>
      <Title order={3}>{title}</Title>
      <Text>{content}</Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        <Button variant="error" style={{ maxWidth: '200px' }} onClick={submit}>
          {buttonText}
        </Button>
      </div>
    </Stack>
  );
};

const useStyles = createStyles(theme => ({
  dangerZoneTitle: {
    color: theme.colors.error,
  },
  dangerZoneCard: {
    borderColor: theme.colors.error,
  },
}));

interface DeactivateProps {
  organizationType: OrganizationType;
}

export const Deactivate = ({ organizationType }: DeactivateProps) => {
  const navigate = useNavigate();

  const { classes } = useStyles();

  const profile = useSelector((state: RootState) => state.user.profile!);

  return (
    <section className="page-section">
      <header>
        <Title order={2} className={classes.dangerZoneTitle}>
          Danger Zone
        </Title>
      </header>
      <Card className={classes.dangerZoneCard}>
        <Stack spacing={30}>
          {organizationType === OrganizationType.INDIVIDUAL && (
            <DeactivateMessage
              title="Deactivate Account"
              content="Deactivating your account will disconnect all connected social media accounts from Areto and disable your account."
              buttonText="Deactivate account"
              submit={() =>
                openDeactivateAccountModal(profile.id, organizationType, () =>
                  navigate('/account-deactivated')
                )
              }
            />
          )}
          {profile.accessLevel !== AccessLevel.ADMIN &&
            organizationType === OrganizationType.COMPANY && (
              <DeactivateMessage
                title="Deactivate Account"
                content="You will no longer have access to your Areto account. All of the connected social media accounts will continue to be connected to Areto unless you were the one who connected them. In that case, another user may need to reconnect your social media accounts using the links above."
                buttonText="Deactivate account"
                submit={() =>
                  openDeactivateAccountModal(profile.id, organizationType, () =>
                    navigate('/account-deactivated')
                  )
                }
              />
            )}
          {profile.accessLevel === AccessLevel.ADMIN &&
            organizationType === OrganizationType.COMPANY && (
              <DeactivateMessage
                title="Deactivate Organization"
                content="Deactivating your organization will disconnect all connected social media accounts from Areto and disable all user accounts, including your own. This action is irreversible."
                buttonText="Deactivate organization"
                submit={() =>
                  openDeactivateOrganizationModal(() =>
                    navigate('/account-deactivated')
                  )
                }
              />
            )}
        </Stack>
      </Card>
    </section>
  );
};
