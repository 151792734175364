import { useState } from 'react';

import { RegisterOrganizationRequestData } from 'src/types';

import client from '../client';

export const useSendRegisterOrganization = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const computeModerationSettings = (moderationType: string) => {
    switch (moderationType) {
      case 'AUTOMODERATE':
        return {
          moderation_enabled: true,
          moderate_spam: true,
          moderate_abuse: true,
        };
      case 'SPAM':
        return {
          moderation_enabled: true,
          moderate_spam: true,
          moderate_abuse: false,
        };
      case 'ABUSE':
        return {
          moderation_enabled: true,
          moderate_spam: false,
          moderate_abuse: true,
        };
      case 'TRACK':
        return {
          moderation_enabled: false,
          moderate_spam: false,
          moderate_abuse: false,
        };
    }
  };

  const send = async (
    data: RegisterOrganizationRequestData
  ): Promise<boolean> => {
    let response;
    setLoading(true);

    console.log(data);

    try {
      response = await client.post('/accounts/organization', {
        name: data.name,
        email: data.email,
        password: data.password,
        account_type: data.accountType,
        organization_name: data.organizationName || data.name,
        ...computeModerationSettings(data.moderationType),
        offensive_language_auto_moderate_threshold:
          data.offensiveLanguageAutoModerateThreshold,
        offensive_language_moderation_queue_threshold:
          data.offensiveLanguageModerationQueueThreshold,
        personal_attack_auto_moderate_threshold:
          data.personalAttackAutoModerateThreshold,
        personal_attack_moderation_queue_threshold:
          data.personalAttackModerationQueueThreshold,
        violent_language_auto_moderate_threshold:
          data.violentLanguageAutoModerateThreshold,
        violent_language_moderation_queue_threshold:
          data.violentLanguageModerationQueueThreshold,
        threat_auto_moderate_threshold: data.threatAutoModerateThreshold,
        threat_moderation_queue_threshold: data.threatModerationQueueThreshold,
      });
    } catch (error: any) {
      setError(error.response.data.response.error);
    } finally {
      setLoading(false);
      return response?.status === 204;
    }
  };

  return { loading, error, send };
};
