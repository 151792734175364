import { Container, Grid, Text } from '@mantine/core';

import { Anchor } from 'src/components';

interface StepProps {
  activeStep: number;
}

export const StepFooter = (props: StepProps) => {
  return (
    <Container p="md">
      {props.activeStep === 0 && (
        <Grid grow={false}>
          <Text size="sm">Already have an Areto account?&nbsp;</Text>
          <Anchor to="/login" size="sm">
            Sign in.
          </Anchor>
        </Grid>
      )}
    </Container>
  );
};
