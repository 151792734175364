import { useState, useCallback, useEffect } from 'react';
import { Response, TrackedUsersStatsResponse } from 'src/types';
import { store } from 'src/redux/store';
import { setStats } from 'src/redux/reducers';

import client from '../client';

const createParams = (): any => {
  const state = store.getState().dashboard;
  const trackedAccountIds = state.trackedAccountsFilter;

  return {
    start: state.dateRangeFilter.start,
    end: state.dateRangeFilter.end,
    ...(trackedAccountIds && trackedAccountIds.length > 0
      ? { tracked_user_ids: trackedAccountIds.join(',') }
      : {}),
  };
};

export const useFetchTrackedUsersStats = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const state = store.getState().dashboard;

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await client.get('/stats/v2/tracked-users', {
        params: createParams(),
      });

      const data = response.data as Response<TrackedUsersStatsResponse>;
      store.dispatch(setStats(data.response));
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.dateRangeFilter, state.trackedAccountsFilter]);

  return { error, loading };
};
