import { useSelector, useDispatch } from 'react-redux';

import { GridToolbarContainer } from '@mui/x-data-grid';
import { Group, Button, Select, createStyles } from '@mantine/core';
import { modals } from '@mantine/modals';

import {
  setActionLogResolvedTypeFilter,
  RootState,
  setActionLogAbuseTypeFilter,
} from 'src/redux/reducers';
import {
  ModerationResolveTypeSelect,
  ModerationActionType,
  AbuseTypeLabels,
} from 'src/types';
import { ActionModal } from 'src/components';

const useStyles = createStyles(theme => ({
  toolbar: {
    width: '100%',
    marginBottom: 8,
  },
}));

const openActionModal = (title: string, action: ModerationActionType) => {
  modals.open({
    title: title,
    centered: true,
    children: <ActionModal action={action} singleAction={false} />,
    size: 'md',
  });
};

interface ActionLogToolbarProps {
  abuseTypeFilter?: string;
  resolvedTypeFilter?: string;
  numSelected: number;
}

const UnresolvedToolbar = ({
  numSelected,
  abuseTypeFilter,
}: ActionLogToolbarProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Group className={classes.toolbar} position="apart">
      {numSelected ? (
        <Group>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal('Confirm: Hide', ModerationActionType.HIDE)
            }
          >
            Hide
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal('Confirm: Delete', ModerationActionType.DELETE)
            }
          >
            Delete
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Not Abusive',
                ModerationActionType.FALSE_POSITIVE
              )
            }
          >
            Not Abusive
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal('Confirm: Archive', ModerationActionType.ARCHIVE)
            }
          >
            Archive
          </Button>
        </Group>
      ) : (
        <div></div>
      )}

      <Select
        data={Object.entries(AbuseTypeLabels).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        placeholder="Status"
        value={abuseTypeFilter}
        onChange={v => dispatch(setActionLogAbuseTypeFilter(v!))}
      />
    </Group>
  );
};

const ResolvedToolbar = ({
  abuseTypeFilter,
  resolvedTypeFilter,
  numSelected,
}: ActionLogToolbarProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Group className={classes.toolbar} position="apart">
      {numSelected ? (
        <Group>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Undo Hide',
                ModerationActionType.UNDO_HIDE
              )
            }
          >
            Undo Hide
          </Button>
          <Button
            variant="outline"
            onClick={() =>
              openActionModal(
                'Confirm: Unarchive',
                ModerationActionType.UNARCHIVE
              )
            }
          >
            Unarchive
          </Button>
        </Group>
      ) : (
        <div></div>
      )}

      <Group>
        <Select
          data={Object.entries(AbuseTypeLabels).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          placeholder="Status"
          value={abuseTypeFilter}
          onChange={v => dispatch(setActionLogAbuseTypeFilter(v!))}
        />

        <Select
          data={Object.entries(ModerationResolveTypeSelect).map(
            ([key, value]) => ({
              label: value,
              value: key,
            })
          )}
          placeholder="Status"
          value={resolvedTypeFilter}
          onChange={v => dispatch(setActionLogResolvedTypeFilter(v!))}
        />
      </Group>
    </Group>
  );
};

export const ActionLogTableToolbar = () => {
  const { classes } = useStyles();
  const actionLog = useSelector((state: RootState) => state.actionLog);

  return (
    <GridToolbarContainer>
      <Group className={classes.toolbar} position="apart">
        {actionLog.unresolvedTab ? (
          <UnresolvedToolbar
            abuseTypeFilter={actionLog.abuseTypeFilter}
            numSelected={actionLog.unresolvedActionLog.selectedIds.length}
          />
        ) : (
          <ResolvedToolbar
            abuseTypeFilter={actionLog.abuseTypeFilter}
            resolvedTypeFilter={actionLog.resolvedTypeFilter}
            numSelected={actionLog.resolvedActionLog.selectedIds.length}
          />
        )}
      </Group>
    </GridToolbarContainer>
  );
};
